import jwtDecode from "jwt-decode";
import { JwtToken } from "../app/models/jwtToken";
import { environment } from "../environments/environments";
import PermissionsService from "./permissionsService";
export const TOKEN_KEY = environment.DIGITAL_TWIN_TOKEN || "@sms:token";

export const isAuthenticated = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token && PermissionsService.tokenValid(token);
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  return PermissionsService.checkApplicationPermission(token);
};

export const loggedUser = () => {
  let token = localStorage.getItem(TOKEN_KEY) || "";
  const authToken: JwtToken = jwtDecode(token);
  return authToken;
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
