import React, { Suspense } from "react";
import { useHistory } from "react-router-dom";
import {
  Autocomplete,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import PositionHistoryTable from "./components/PositionHistory";
import { installationTreeResource } from "../../../../services/suspenders";
import { useStore } from "../../stores/store";
import { useTranslation } from "react-i18next";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
const useStyles = makeStyles((theme) => ({
  autocompleteTextField: theme.autocompleteTextField,

  title: {
    color: theme.textSecondary,
    width: "100%",
  },
  link: {
    display: "inline",
    cursor: "pointer",
    fontSize: 17,
    color: theme.link,
    border: `1px solid ${theme.mediumContrastBorder}`,
    borderRadius: 10,
    marginRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
  valueDiv: {
    width: "100%",
    marginBottom: 10,
  },
  value: {
    fontSize: 17,
    display: "inline",
  },
  linkValueDiv: {
    width: "100%",
    marginTop: 5,
    marginBottom: 15,
  },
  properties: {
    width: "100%",
  },
  container: {
    width: "100%",
  },
}));

interface Props {
  position: any;
  setPosition: any;
  partId: string;
  setMainView: any;
  setDetailsView: any;
}

export default function InstallationPlaces({
  setMainView,
  setDetailsView,
  position,
  setPosition,
  partId,
}: Props) {
  const { t } = useTranslation();
  const { casterSetupStore } = useStore();
  const { rightGridHeight } = casterSetupStore;
  const history = useHistory();
  const classes = useStyles();
  const { installableParts } = installationTreeResource.read();
  const part = installableParts[partId];
  const installationPositions = part.installationPostions;
  const positionArray = Object.values(installationPositions).map((x) => ({
    label: x.name,
    id: x.id,
  }));
  const selectedPosition = positionArray.find(
    (x) => x.id === position?.position
  )
    ? positionArray.find((x) => x.id === position.position)
    : "";
  const positionAttr = installationPositions.find(
    (x) => x.id === position?.position
  );

  return (
    <>
      <Autocomplete
        className={classes.autocompleteTextField}
        popupIcon={<KeyboardArrowDown/>}
        onChange={(event: any, newValue: any) => {
          if (newValue != null) {
            setPosition(newValue.id);
          } else {
            setPosition(undefined);
          }
        }}
        value={selectedPosition}
        disablePortal
        id="combo-box-demo"
        options={positionArray}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("castersetup.installationPlaces.selectAPosition")}
            variant="outlined"
            InputLabelProps={{ shrink: false }}
          ></TextField>
        )}
      />

      {positionAttr ? (
        <div
          style={{
            marginTop: 5,
            height: rightGridHeight ? rightGridHeight - 48 : undefined,
            overflow: "auto",
          }}
        >
          <Grid container item className={classes.container}>
            <Typography className={classes.title} variant="caption">
              {t("grid.label.id")}
            </Typography>
            <div className={classes.valueDiv}>
              <p className={classes.value}>{positionAttr.id}</p>
            </div>

            <Typography className={classes.title} variant="caption">
              {t("castersetup.installationPlaces.type")}
            </Typography>
            <div className={classes.valueDiv}>
              <p className={classes.value}>revolver-cooled SMS group</p>
            </div>

            <Typography className={classes.title} variant="caption">
              {t("castersetup.installationPlaces.currentlyInstalled")}
            </Typography>
            {positionAttr.currentlyInstalled ? (
              <div className={classes.linkValueDiv}>
                <p
                  className={classes.link}
                  onClick={() => {
                    history.push(
                      `../caster/${positionAttr.currentlyInstalled!.id}`
                    );
                    setMainView("1");
                    setDetailsView("1");
                    setPosition(undefined);
                  }}
                >
                  {`${positionAttr.currentlyInstalled.name}`}
                </p>
              </div>
            ) : (
              <div className={classes.valueDiv}>
                <p className={classes.value}>
                  {t("castersetup.installationPlaces.noPartInstalled")}
                </p>
              </div>
            )}
            <Typography className={classes.title} variant="caption">
              {t("castersetup.installationPlaces.canBeInstalled")}
            </Typography>
            {positionAttr.installableParts.length !== 0 ? (
              <Grid container style={{ marginTop: -15 }}>
                {positionAttr.installableParts.map((inspart) => (
                  <p
                    key={inspart.id}
                    className={classes.link}
                    onClick={() => {
                      if (positionAttr.installableParts) {
                        history.push(`../caster/${inspart.id}`);
                        setMainView("1");
                        setDetailsView("1");
                        setPosition(undefined);
                      }
                    }}
                  >
                    {inspart.name}
                  </p>
                ))}
              </Grid>
            ) : (
              <div className={classes.valueDiv}>
                <p className={classes.value}>
                  {t("castersetup.installationPlaces.noPartAvailable")}
                </p>
              </div>
            )}

            <Typography className={classes.title} variant="caption">
              {t("castersetup.installationPlaces.installationHistory")}
            </Typography>
            <Suspense fallback={<CircularProgress />}>
              <PositionHistoryTable
                setMainView={setMainView}
                setDetailsView={setDetailsView}
                positionId={position?.position}
              />
            </Suspense>
          </Grid>
        </div>
      ) : (
        <Typography>
          {t("castersetup.installationPlaces.noPositionSelected")}
        </Typography>
      )}
    </>
  );
}
