import React, { useEffect, useState } from "react";
import { Agent } from "../../../services/plantviewsService";
import useProgressBar from "../../../common/hooks/useProgressBar";
//Material UI
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { PlantviewType } from "../../../app/models/plantview";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  selectInput: { ...theme.selectInput, width: "100%" }
}));

interface Props {
  onSelectedItem: (id: number) => void;
  selectedValue: number;
  hideLabel: boolean;
  width: string;
}

export default function PlantviewsList({
  selectedValue,
  onSelectedItem,
  hideLabel,
  width,
}: Props) {
  const classes = useStyles();
  // const assetContext = useContext(AssetContext);
  // const { viewType } = assetContext;
  const { showProgressBar, hideProgressBar } = useProgressBar();
  const [plantviews, setPlantviews] = useState<PlantviewType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      showProgressBar();
      const result = await Agent.Plantviews.list();
      setPlantviews(result);
      hideProgressBar();
    })();
  }, [hideProgressBar, showProgressBar]);

  const selectedItemHandler = (e: SelectChangeEvent) => {
    if (e.target.value) {
      onSelectedItem(parseInt(e.target.value));
    }
  };

  return (
    <div style={{ width: width }}>
      {!hideLabel && (
        <Typography variant="body2" gutterBottom>
          {t("page.plantview.viewType")}
        </Typography>
      )}
      <Select
        style={{ borderRadius: 0 }}
        IconComponent={KeyboardArrowDownIcon}
        id="plantview"
        className={classes.selectInput}
        onChange={selectedItemHandler}
        value={`${selectedValue}`}
      >
        {plantviews.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
