import axios from "axios";
import { environment } from "../environments/environments";

const baseURL = environment.ASSET_DICTIONARY_API;

async function getParts() {
  try {
    const response = await axios.get(`${baseURL}/Parts`);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

async function getPart(id) {
  try {
    const response = await axios.get(`${baseURL}/Parts/${id}`);
    if (response.data) {
      return { id, ...response.data };
    }
  } catch (error) {
    console.error(error);
  }
}

async function getPartsByName(name) {
  try {
    const response = await axios.get(`${baseURL}/Parts`, {
      params: { name: name },
    });
    return response.data || [];
  } catch (error) {
    console.error(error);
  }
}

async function getAllProperties() {
  try {
    const response = await axios.get(`${baseURL}/Properties`);
    return response.data ?? [];
  } catch (error) {
    console.error(error);
  }
}

//Add a property to the node
async function addProperty(payload) {
  try {
    //PropertyId,NodeId,RelationshipId
    const response = await axios.post(`${baseURL}/Parts/Properties`, payload);
    console.log(response.data);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

//Add a system to the node
async function addSystem(payload) {
  try {
    //PropertyId,NodeId,RelationshipId
    await axios.post(`${baseURL}/System`, payload).then((response) => {
      console.log(response.data);
      return true;
    });
  } catch (error) {
    console.error(error);
    return false;
  }
}

const NodeService = {
  getParts,
  getPart,
  getPartsByName,
  getAllProperties,
  addProperty,
  addSystem,
};

export default NodeService;
