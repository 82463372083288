const USER_PREFERENCE_ROWS_PER_PAGE = "dt:user:rows:per:page";

class UserPreferenceService {
  getRowsPerPage() {
    return localStorage.getItem(USER_PREFERENCE_ROWS_PER_PAGE) ?? "5";
  }

  setRowsPerPage(pages: string) {
    return localStorage.setItem(USER_PREFERENCE_ROWS_PER_PAGE, pages);
  }
}

const userPreferenceService = new UserPreferenceService();

export default userPreferenceService;
