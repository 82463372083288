
import { ListItem } from "../models/listItem";
import { Part } from "../models/part";
import { PartDetail } from "../models/partdetails";
import { PartItem } from "../models/partitem";
import {
  AddViewRelationshipRequest,
  ViewRelationship,
} from "../models/plantview";
import { DecPlantModelRequest } from "../models/decJobs";
import { AddPartPropertyRequest } from "../models/property";
import { Relationship } from "../models/relationship";
import { AddPartSystemRequest } from "../models/system";
import { environment } from "../../environments/environments";
import { request } from './serviceBase';
import { Ontology } from './ontology';
import { ReferenceData } from "./referenceData";
const baseURL = environment.ASSET_DICTIONARY_API;

const Parts = {
  details: (part: string) =>
    request.get<PartDetail>(`${baseURL}/Parts/${part}/Properties`),
  list: (part: string) =>
    request.get<PartItem[]>(`${baseURL}/Parts?name=${part}`),
  create: (part: Part) => request.post<void>(`${baseURL}/Parts`, part),
  delete: (id: string) => request.delete<Part>(`${baseURL}/Parts/${id}`),
  addProperty: (property: AddPartPropertyRequest) =>
    request.post<void>(`${baseURL}/Parts/Properties`, property),
  deleteProperty: (id: string) =>
    request.delete<Part>(`${baseURL}/Parts/Properties/${id}`),
  addSystem: (property: AddPartSystemRequest) =>
    request.post<void>(`${baseURL}/Parts/Properties`, property),
};

const Relationships = {
  list: () =>
    request.get<Relationship[]>(`${baseURL}/ReferenceData/RelationshipTypes`),
};

const PropertyTypes = {
  list: () => request.get<ListItem[]>(`${baseURL}/ReferenceData/PropertyTypes`),
};

const Plantviews = {
  relationships: (id: number) =>
    request.get<ViewRelationship[]>(
      `${baseURL}/Plantviews/relationships?viewTypeId=${id}`
    ),
  addRelationship: (relationship: AddViewRelationshipRequest) =>
    request.post<void>(`${baseURL}/Plantviews/Relationships`, relationship),
  deleteRelationship: (id: number) =>
    request.delete<Part>(`${baseURL}/Plantviews/Relationships/${id}`),
};

const PlantModel = {
  get: (decId: string) =>
    request.get<DecPlantModelRequest>(`${baseURL}/PlantModels?partId=${decId}`),
};

export const Agent = {
  Parts,
  Relationships,
  PropertyTypes,
  Plantviews,
  Ontology,
  PlantModel,
  ReferenceData
};
