import React from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import DigitalTwin from "./DigitalTwin";
import SignIn from "./pages/Login";
import oAuth from "./pages/oAuth";
import { history } from "./.";
import { isAuthenticated } from "./services/auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (!isAuthenticated()) {
    return <Redirect to={{ pathname: "/login" }} />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/oAuth" component={oAuth} />
      <Route path="/login" component={SignIn} />
      <PrivateRoute path="/app" component={DigitalTwin} />
      <Route path="*" component={SignIn} />
      <Route exact path="/" component={SignIn} />
    </Switch>
  </Router>
);

export default Routes;
