import React, { useContext, useState } from "react";
//Material-UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Select, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AssetContext from "../../../../context/asset-context";
import { ListItem } from "../../../../app/models/listItem";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    alignItems: "center",
  },
  label: {
    flexBasis: "40%",
    maxWidth: "40%",
  },
  item: {
    flexBasis: "60%",
    maxWidth: "60%",
  },
}));

interface Props {
  onDescChanged: (description: string) => void;
  onIdChanged: (id: string) => void;
  onSystemChanged: (id: string) => void;
}

export default function SystemSection({
  onDescChanged,
  onIdChanged,
  onSystemChanged,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const assetContext = useContext(AssetContext);
  const { externalSystems } = assetContext;
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [selectedSystem, setSelectedSystem] = useState("");

  const handleDescription = (text: string) => {
    setDescription(text);
    onDescChanged(text);
  };

  const handleId = (id: string) => {
    setId(id);
    onIdChanged(id);
  };

  const handleSystemSelect = (id: string) => {
    setSelectedSystem(id);
    onSystemChanged(id);
  };

  return (
    <>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.addproperty.system.label")}
        </Box>
        <Select
          id="systems"
          className={classes.item}
          onChange={(e) => handleSystemSelect(e.target.value as string)}
          value={selectedSystem}
        >
          {externalSystems.map((item: ListItem) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.addproperty.identifier.label")}
        </Box>
        <TextField
          id="systemId"
          className={classes.item}
          onChange={(e) => {
            handleId(e.target.value as string);
          }}
          value={id}
        />
      </Box>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.addproperty.description.label")}
        </Box>
        <TextField
          id="systemDesc"
          className={classes.item}
          onChange={(e) => {
            handleDescription(e.target.value as string);
          }}
          value={description}
        />
      </Box>
    </>
  );
}
