// todo: move to database, maybe
const panels = [
  {
    id: "1",
    name: "Segment 4",
    children: [
      {
        id: "genius_cm_tree",
        name: "Genius CM",
        description: "Tree with Genius CM Nodes",
        visualization_type: "tree",
      },
      {
        id: "genius_cm_signal",
        name: "Genius CM",
        description: "Char and Alarm status Signal for selected part",
        visualization_type: "chart",
      },
      {
        id: "genius_cm_gauge",
        name: "Genius CM",
        description: "Gauge Alarm status Signal for selected part",
        visualization_type: "gauge",
      },
      {
        id: "dec",
        name: "DEC",
        description: "Job List for selected part",
        visualization_type: "list",
      },
      {
        id: "edoc",
        name: "e-Doc",
        description: "E-Docs Plant View",
        visualization_type: "e-doc",
      },
    ],
  },
];

export default panels;
