import React, { useContext, useState } from "react";
import AssetContext from "../../../context/asset-context";
//Material-UI
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { Agent } from "../../../services/plantviewsService";

export default function AssetTreeView(props) {
  const assetContext = useContext(AssetContext);
  const { onSelectPart, viewType } = assetContext;
  const [expanded, setExpanded] = useState([]);
  const [childNodes, setChildNodes] = useState(null);

  const handleToggle = (event, nodeIds) => {
    event.persist();
    let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  async function fetchChildNodes(id) {
    let plantview = await Agent.Plantviews.children(id, viewType);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          children: plantview.children,
        });
      }, 1000);
    });
  }

  const handleSelect = async (event, id) => {
    event.persist();
    let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
    if (!iconClicked) {
      onSelectPart(event, id);
    } else {
      fetchChildNodes(id).then((result) =>
        setChildNodes(
          result.children.map((node) => (
            <AssetTreeView
              key={node.id}
              nodeId={node.id}
              {...node}
              action={props.action}
            />
          ))
        )
      );
    }
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      <TreeItem nodeId={props.id} label={props.name}>
        {childNodes || [<div key="stub" />]}
      </TreeItem>
    </TreeView>
  );
}
