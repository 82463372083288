/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";

import { RUNNER_ROW_HEIGHT } from "../../../../constants";

import { CockPitTreeNode } from "../../../../app/models/cockPitTreeNode";

import { PannelLocation } from "../../../../app/models/presentation";

interface Props {
  onSelectecItem: (node: CockPitTreeNode) => void;
  panel?: PannelLocation;
  nodes: CockPitTreeNode[];
}

export default function TreeViewPannel({
  onSelectecItem,
  panel,
  nodes,
}: Props) {
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      overflow: "auto",
      maxHeight: panel
        ? panel.h * RUNNER_ROW_HEIGHT * 0.9
        : RUNNER_ROW_HEIGHT * 0.9,
    },
  });
  const classes = useStyles();

  const selectNodeHandler = (param: CockPitTreeNode) => {
    if (param) {
      onSelectecItem(param);
    }
  };

  const renderTree = (node: CockPitTreeNode) => (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      title={node.text}
      label={node.text}
      onLabelClick={() => selectNodeHandler(node)}
    >
      {Array.isArray(node.children)
        ? node.children.map((node: CockPitTreeNode) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <>
      {nodes && nodes.length > 0 && (
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {nodes.map((node) => renderTree(node))}
        </TreeView>
      )}
    </>
  );
}
