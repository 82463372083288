import { GeniusCMView } from "./../app/models/geniusCmView";
import axios from "axios";
import qs from "qs";
import { endOfDay, format, startOfDay } from "date-fns";

const baseUrl = "/geniuscm";

async function getData(
  startDate?: Date,
  endDate?: Date,
  nodeId?: string,
  daily = 1
) {
  try {
    // fetch data from api
    let initialDate = startDate ? startDate : startOfDay(new Date());
    let finalDate = endDate ? endDate : endOfDay(new Date());
    if (!nodeId || nodeId === "") {
      return [];
    }
    const view = await getViews(nodeId);

    if (view && view.triple) {
      const requestBody = {
        ana_class: view.triple.ana_class,
        ana_id: view.triple.ana_id,
        ana_name: view.triple.ana_name,
        startdate: format(initialDate, "yyyy-MM-dd HH:mm:ss"),
        enddate: format(finalDate, "yyyy-MM-dd HH:mm:ss"),
        cols: view.ycols,
        daily: daily,
      };

      const response = await axios.post(
        `${baseUrl}/GetData.ashx`,
        qs.stringify(requestBody),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return response.data ?? [];
    }
    return [];
  } catch (error) {
    console.error(error);
  }
}

async function getAlarmStatus(node?: string) {
  try {
    const response = await axios.get(`${baseUrl}/GetAlarmStatus.ashx`);

    if (node === undefined) {
      return response.data;
      // eslint-disable-next-line no-prototype-builtins
    } else if (node && response.data.hasOwnProperty(node)) {
      return response.data[node];
    } else return null;
  } catch (error) {
    console.error(error);
  }
}

async function getNodes() {
  try {
    const response = await axios.get(`${baseUrl}/GetNodes.ashx`);

    return response.data ?? [];
  } catch (error) {
    console.error("error on get nodes", error);
  }
}

async function getViews(nodeId?: string) {
  try {
    const response = await axios.get<GeniusCMView[]>(
      `${baseUrl}/GetViews.ashx`
    );

    return response.data.find((v) => v.id === nodeId);
  } catch (error) {
    console.error("error on get views", error);
  }
}

const GeniusCMService = {
  getData,
  getAlarmStatus,
  getNodes,
  getViews,
};

export default GeniusCMService;
