import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import { HiStatusOnline } from "react-icons/hi";
import { useTranslation } from "react-i18next";

function getRandomArbitrary(min: any, max: any) {
  return Math.floor(Math.random() * (max - min) + min);
}

export default function UpTime() {
  const { t } = useTranslation();
  const [currentTime, setTime] = useState(getRandomArbitrary(5000, 20000))


  useEffect(() => {
    const interval = setInterval(() => {
      setTime(currentTime + 60)
    }
      , 60000);
    return () => clearInterval(interval);
   // eslint-disable-next-line
  }, [currentTime]);

  return (
    <div style={{ height: 120, minWidth: 220, backgroundColor: "#F2F2F2", border: "1px solid #E0E0E0", borderRadius: 5, cursor: "pointer" }}>
      <Grid container item style={{ marginLeft: 10, marginTop: 10, width: "auto" }} direction="row" justifyContent="flex-start" alignItems="center">

        <HiStatusOnline style={{ height: 20, width: 20, color: "green" }} />
        <Typography variant="h5" style={{ marginLeft: 5 }} >{t("castersetup.maintenance.upTime")}</Typography>
      </Grid>
      <Typography variant="h6" style={{ marginLeft: 10, marginTop: 5 }} >
        {(currentTime >= 36000) ? Math.floor(currentTime / 3600) : `0${Math.floor(currentTime / 3600)}`}
        :
        {((currentTime - (Math.floor(currentTime / 3600) * 3600)) >= 600) ? Math.floor((currentTime - (Math.floor(currentTime / 3600) * 3600)) / 60) :
          `0${Math.floor((currentTime - (Math.floor(currentTime / 3600) * 3600)) / 60)}`} HH:MM</Typography>
      <Grid container item style={{ marginLeft: 10, width: "auto" }} direction="row" justifyContent="flex-start" alignItems="center">

      </Grid>
    </div>

  )
}