import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, MenuItem, Select, TextField } from "@material-ui/core";
import { ListItem } from "../../../../app/models/listItem";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { Agent } from "../../../../app/api/agent";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    alignItems: "center",
  },
  label: {
    flexBasis: "40%",
    maxWidth: "40%",
  },
  item: {
    flexBasis: "60%",
    maxWidth: "60%",
  },
  textfield: {
    ...theme.autocompleteTextField, flexBasis: "60%",
    maxWidth: "60%",
  },
  select: {
    ...theme.selectInput, flexBasis: "60%",
    maxWidth: "60%"
  }
}));

interface Props {
  onNameChange: (value: string) => void;
  onSelectType: (id: number) => void;
  onRemarksChange: (value: string) => void;
}

export default function EquipmentBody({
  onNameChange,
  onSelectType,
  onRemarksChange,
}: Props) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [nodeTypes, setNodetTpes] = useState<ListItem[]>([]);
  const [remarks, setRemarks] = useState("");
  const [selectedNodeType, setSelectedNodeType] = useState(-1);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const types = await Agent.ReferenceData.getNodeTypes();
      if (types) {
        setNodetTpes(types);
      }
    })();
  }, []);

  const handleNameChange = (value: string) => {
    setName(value);
    onNameChange(value);
  };

  const handleRemarksChange = (value: string) => {
    setRemarks(value);
    onRemarksChange(value);
  };

  const handleTypeChange = (e: React.ChangeEvent<{ value: any }>) => {
    e.preventDefault();
    e.stopPropagation();
    const id = e.target.value;
    setSelectedNodeType(id);
    if (id) {
      onSelectType(id);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.addequipment.part.label")}:
        </Box>
        <TextField
          id="Name"
          className={classes.textfield}
          onChange={(e) => {
            handleNameChange(e.target.value as string);
          }}
          value={name}
        />
      </Box>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.addequipment.type.label")}:
        </Box>
        <Select
          id="Type"
          style={{ borderRadius: 0 }}
          IconComponent={KeyboardArrowDown}
          className={classes.select}
          onChange={handleTypeChange}
          value={selectedNodeType}
        >
          {nodeTypes.map((item: ListItem) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.addequipment.remarks.label")}:
        </Box>
        <TextField
          id="Remarks"
          className={classes.textfield}
          onChange={(e) => {
            handleRemarksChange(e.target.value as string);
          }}
          value={remarks}
        />
      </Box>
    </div>
  );
}
