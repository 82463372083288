import React from "react";
import alphaShape from "alpha-shape";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import {
  mergeBufferGeometries,
  mergeVertices,
  DRACOLoader,
  FBXLoader,
  GLTFLoader,
  MeshoptDecoder,
  SimplifyModifier,
} from "three-stdlib";

import OptimerJson from "./optimer_regular.typeface.json";
import { Matrix4 } from "three";

declare module "three-stdlib" {
  // MeshoptDecoder is not typed in three-stdlib@2.0.3
  function MeshoptDecoder(): any;
}

export function threeToJSX(
  obj: THREE.Object3D | THREE.Mesh,
  addExtraProps: (a: THREE.Object3D, b: any) => any = (obj, props) => props
) {
  /* transforms a THREE.Group into a JSX tree for use with react-three-fiber
   */
  const jsxChildren = obj.children
    .map((c) => threeToJSX(c, addExtraProps))
    .filter((x) => x !== undefined);

  let {
    // eslint-disable-next-line no-unused-vars
    parent,
    children,
    id,
    onAfterRender,
    onBeforeRender,
    type,
    geometry,
    material,
    position,
    quaternion,
    rotation,
    scale,
    up,
    uuid,
    ...props
  } = obj as THREE.Mesh; // type assertion is a bald lie to shut-up typescript

  props = addExtraProps(obj, props);
  props = { ...props, userData: { ...(props.userData || {}), backRef: obj } };

  (position as unknown as number[]) = position.toArray();
  (quaternion as unknown as number[]) = quaternion.toArray();
  (rotation as unknown as number[]) = rotation.toArray();
  (scale as unknown as number[]) = scale.toArray();
  (up as unknown as number[]) = up.toArray();

  if (obj.type === "Group" || obj.type === "Object3D") {
    return (
      <group
        key={uuid}
        position={position}
        quaternion={quaternion}
        rotation={rotation}
        scale={scale}
        up={up}
        uuid={uuid}
        {...props}
      >
        {jsxChildren}
      </group>
    );
  } else if (obj.type === "Mesh") {
    return (
      <mesh
        key={uuid}
        args={[geometry, material]}
        position={position}
        quaternion={quaternion}
        rotation={rotation}
        scale={scale}
        up={up}
        uuid={uuid}
        {...props}
      />
    );
  }
}

export function deleteObject3D(group: THREE.Object3D) {
  const parent = group.parent;
  if (!parent) return group;
  parent.remove(group);

  return parent;
}

export function getAggregateTransform(
  root: THREE.Object3D,
  obj: THREE.Object3D,
  inclusive = true
) {
  /* calculate the aggregate transform from root (inclusive) to obj (inclusive if inclusive=true) */
  if (!inclusive && !obj.parent) return new Matrix4();

  // seems you should not trust .matrixWorldNeedsUpdate
  root.updateMatrixWorld(true);

  if (obj.parent && !inclusive) obj = obj.parent;

  const transformation = obj.matrix.clone();
  //const transformation = new THREE.Matrix4();
  //transformation.compose(obj.position, obj.quaternion, obj.scale);

  //const temp = new THREE.Matrix4();

  while (obj.parent && obj !== root) {
    obj = obj.parent;

    //temp.compose(obj.position, obj.quaternion, obj.scale);
    transformation.premultiply(obj.matrix);
  }

  return transformation;
}

export function applyParentTransform(child: THREE.Object3D) {
  if (!child.parent) return child;

  // seems you should not trust .matrixWorldNeedsUpdate
  child.parent.updateMatrixWorld(true);

  const mat = child.parent.matrix;
  child.applyMatrix4(mat);

  return child;
}

export function hoistChildren(group: THREE.Group) {
  if (!group.parent) return group;

  let { children, parent } = group;
  children = children.slice(); // shallow copy as parent.add etc mutate children

  children.forEach((child: THREE.Object3D) => {
    applyParentTransform(child);
    parent.add(child);
  });
  parent.remove(group);

  return parent;
}

/* TODO: for the typescript experts: extraCompares keys should be attribute names on THREE.Group and the binop signature
    should be the attributes type (x2) => boolean
*/
export function fuseChildGroups(
  parentGroup: THREE.Group,
  extraCompares: { [name: string]: (a: any, b: any) => boolean } = {}
) {
  /* move children under first group with identical position/quaternion/scale */
  function _fuseGroups(grp: THREE.Object3D, arr: Array<THREE.Object3D>) {
    // ATTN: do NOT pass parent.children as arr, this would mutate via parent.remove
    const remain: Array<THREE.Object3D> = [];
    let aggregated: THREE.Object3D | undefined = undefined;

    arr.forEach((other) => {
      if (
        grp.type === "Group" &&
        other.type === "Group" &&
        Object.entries(extraCompares).every(([k, v]) =>
          v((grp as any)[k], (other as any)[k])
        )
      ) {
        // TODO: think about .distance for equals check
        if (aggregated === undefined) {
          aggregated = new THREE.Group();
          grp.parent!.add(aggregated);
          grp.parent!.remove(grp);
          aggregated.add(grp);

          // TODO: this is model mapper specific
          aggregated.userData.__smsAssetDictionaryId =
            grp.userData.__smsAssetDictionaryId;
          aggregated.userData.__smsAssetDictionaryName =
            grp.userData.__smsAssetDictionaryName;
          grp.userData.__smsAssetDictionaryId = undefined;
          grp.userData.__smsAssetDictionaryName = undefined;
        }
        other.parent!.remove(other);
        aggregated.add(other);

        // TODO: this is model mapper specific
        other.userData.__smsAssetDictionaryId = undefined;
        other.userData.__smsAssetDictionaryName = undefined;
      } else {
        remain.push(other);
      }
    });

    return remain;
  }

  let [head, ...rest] = parentGroup.children;

  while (rest.length) {
    [head, ...rest] = _fuseGroups(head, rest);
  }

  return parentGroup;
}

export function splitGroup(grp: THREE.Group) {
  /* replace grp with grp.children.length groups containing a single child each */
  const parent = grp.parent;
  if (parent) {
    grp.children.slice().forEach((cc: THREE.Object3D) => {
      const newGroup = new THREE.Group();
      newGroup.copy(grp, false);

      newGroup.add(cc);
      parent!.add(newGroup);
    });
    parent.remove(grp);

    return parent;
  } else {
    return grp;
  }
}

export function insertGroup(obj: THREE.Object3D) {
  /* insert a new THREE.Group above obj */
  const newGroup = new THREE.Group();

  const oldParent = obj.parent;
  newGroup.add(obj);

  if (oldParent) {
    oldParent.add(newGroup);
  }
  return newGroup;
}

export function insertGroupBelow(obj: THREE.Object3D) {
  /* insert a new THREE.Group below obj */
  const newGroup = new THREE.Group();
  const children = obj.children.slice();
  children.forEach((c) => newGroup.add(c));
  obj.add(newGroup);
  return obj;
}

export function searchMeshes(obj: THREE.Object3D, meshes?: THREE.Mesh[]) {
  meshes = meshes !== undefined ? meshes : [];
  if (obj.type === "Mesh") {
    meshes.push(obj as THREE.Mesh);
  }
  obj.children.forEach((c) => searchMeshes(c, meshes));

  return meshes;
}

export function create3dText(text: string) {
  const fonts = new THREE.FontLoader();
  const optimer = fonts.parse(OptimerJson);

  const materials = [
    new THREE.MeshPhongMaterial({ color: 0xffffff, flatShading: true }), // front
    new THREE.MeshPhongMaterial({ color: 0xffffff }), // side
  ];

  const textGeo = new THREE.TextGeometry(text, {
    font: optimer,
    size: 80,
    height: 5,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 10,
    bevelSize: 8,
    bevelOffset: 0,
    bevelSegments: 5,
  });
  return new THREE.Mesh(textGeo, materials);
}

export function stringifyObject3D(obj: THREE.Object3D, meta = undefined) {
  const jsonObj = obj.toJSON(meta);
  const blobData = [
    '{"metadata": ',
    JSON.stringify(jsonObj.metadata),
    ', "object": ',
    JSON.stringify(jsonObj.object),
    ', "geometries": [',
  ];
  jsonObj.geometries.forEach((g: Object, idx: number) => {
    blobData.push(JSON.stringify(g));
    if (idx < jsonObj.geometries.length - 1) blobData.push(",");
  });
  blobData.push('], "materials": [');
  jsonObj.materials.forEach((m: Object, idx: number) => {
    blobData.push(JSON.stringify(m));
    if (idx < jsonObj.materials.length - 1) blobData.push(",");
  });
  blobData.push("]}");

  return new Blob(blobData, { type: "application/json" });
}

export function mergeMeshes(obj: THREE.Object3D) {
  /* replace `obj` and the full tree below with a single mesh */
  const meshesByMaterial = new Map<THREE.Material, THREE.BufferGeometry[]>();
  const geometries: THREE.BufferGeometry[] = [];
  const materials: THREE.Material[] = [];

  obj.updateMatrixWorld(true);
  const meshes: THREE.Mesh[] = searchMeshes(obj);

  // run over the meshes and apply the transformations obj->mesh to the geometry,
  // sort by material (we will create one geometry per material first)
  meshes.forEach((m) => {
    const geom = convertInterleavedGeometry(m.geometry);
    const mat = getAggregateTransform(obj, m, true);
    geom.applyMatrix4(mat);

    if (Array.isArray(m.material))
      // so multi-material is possible but the docs do not tell you how
      throw new Error("Multiple materials per mesh not supported, yet.");
    if (meshesByMaterial.has(m.material)) {
      meshesByMaterial.get(m.material)!.push(m.geometry);
    } else {
      meshesByMaterial.set(m.material, [m.geometry]);
    }
  });

  // merge geometries per material
  for (let [mat, geoms] of meshesByMaterial.entries()) {
    const geom = mergeBufferGeometries(geoms);
    if (geom === null) throw new Error("Geometries cannot be merged.");

    geometries.push(geom);
    materials.push(mat);
  }

  // create a geometry with subgroups per material
  const geom = mergeBufferGeometries(geometries, true);
  if (geom === null) throw new Error("Geometries cannot be merged.");

  return new THREE.Mesh(
    geom,
    materials.length === 1 ? materials[0] : materials
  );
}

export function convertInterleavedGeometry(geo: THREE.BufferGeometry) {
  /* high-level functions like .applyMatrix4() or .toNonIndexed() do not seem to be relyable with 
       interleaved buffer attributes. This copying the input geometry turning interleaved attributes
       into normal attributes.
    */
  const result = new THREE.BufferGeometry();

  Object.keys(geo.attributes).forEach((attr) => {
    const bufferAttr = geo.attributes[attr];
    const itemSize = bufferAttr.itemSize;

    const data = [];
    for (let ii = 0; ii < bufferAttr.count; ii++) {
      data.push(bufferAttr.getX(ii));
      if (itemSize > 1) {
        data.push(bufferAttr.getY(ii));
        if (itemSize > 2) {
          data.push(bufferAttr.getZ(ii));
          if (itemSize > 3) {
            data.push(bufferAttr.getW(ii));
          }
        }
      }
    }
    result.setAttribute(
      attr,
      new THREE.BufferAttribute(new Float32Array(data), itemSize)
    );
  });

  if (geo.index) {
    result.setIndex(
      new THREE.BufferAttribute(
        geo.index.array,
        geo.index.itemSize,
        geo.index.normalized
      )
    );
  }

  return result;
}

interface ShrinkwrapOptions {
  alpha?: number;
  simplify?: number;
  material?: THREE.Material | THREE.Material[];
}

export function shrinkwrap(
  obj: THREE.Object3D,
  { alpha = 0.0001, simplify = 0.0, material = undefined }: ShrinkwrapOptions
) {
  /* build a shrinkwrap around `obj`

       WARNING: this is not production ready, yet.
         - `alpha-shape` cannot cope with large objects and finding a suitable alpha
           is an open problem (you'll get an empty mesh/the convex hull if you go
           to large/small).
         - `SimplifyModifier` cannot cope with degenerate faces (which seem to be in our models)
           and is super slow in the debugger.

    */
  obj.updateMatrixWorld(true);

  const meshes: THREE.Mesh[] = searchMeshes(obj);

  const transformedGeos = meshes.map((m, idx) => {
    // Apply Object3D transforms to the geometries
    let geo = convertInterleavedGeometry(m.geometry);
    const mat = getAggregateTransform(obj, m, true);
    geo.applyMatrix4(mat);

    return geo;
  });

  // merge all geometries
  const initialGeom = mergeBufferGeometries(transformedGeos);
  transformedGeos.forEach((g) => g.dispose());

  if (initialGeom === null) throw new Error("Geometries cannot be merged.");

  initialGeom.computeBoundingBox();
  const box = initialGeom.boundingBox;
  const { min, max } = box!;
  max.subVectors(max, min);
  const scale = Math.min(max.x, max.y, max.z);

  const mergedGeo = mergeVertices(initialGeom);
  initialGeom.dispose();
  let simplifiedGeom = mergedGeo;
  if (simplify && mergedGeo.attributes.position.count > 1000) {
    const modifier = new SimplifyModifier();

    const count = Math.floor(initialGeom.attributes.position.count * simplify); // number of vertices to remove
    simplifiedGeom = modifier.modify(simplifiedGeom, count);

    mergedGeo.dispose();
  }

  let geom;
  if (alpha >= 0) {
    const initialPositions = simplifiedGeom.attributes.position; // TODO: can we use this directly (instead of points)?
    const points: number[][] = [];

    if (!simplifiedGeom.index) {
      for (let ii = 0; ii < initialPositions.count; ii++) {
        points.push([
          initialPositions.getX(ii),
          initialPositions.getY(ii),
          initialPositions.getZ(ii),
        ]);
      }
    } else {
      for (let ii = 0; ii < simplifiedGeom.index.count; ii++) {
        const jj = simplifiedGeom.index.getX(ii);
        points.push([
          initialPositions.getX(jj),
          initialPositions.getY(jj),
          initialPositions.getZ(jj),
        ]);
      }
    }

    const idxs = alphaShape(alpha * scale, points);

    const vertices = Float32Array.from(
      idxs
        .flat()
        .map((idx) => points[idx])
        .flat()
    );
    geom = new THREE.BufferGeometry();
    geom.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
    simplifiedGeom.dispose();
  } else {
    geom = simplifiedGeom;
  }

  material = material || meshes[0].material;

  return new THREE.Mesh(geom, material);
}

export function loadFBXFile(f: File, path: string) {
  return f.arrayBuffer().then((data: ArrayBuffer) => {
    const loader = new FBXLoader();
    return loader.parse(data, path);
  });
}

export function loadGLTFFile(f: File, path: string, doNotUseDRACO = false) {
  const loader = new GLTFLoader();
  if (!doNotUseDRACO) {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
    ); // TODO: this pulls decoders from web, should be local
    dracoLoader.preload();
    loader.setDRACOLoader(dracoLoader);
    loader.setMeshoptDecoder(MeshoptDecoder);
  }

  return f.arrayBuffer().then((buf) => {
    return new Promise((resolve, reject) => {
      loader.parse(buf, path, resolve, reject);
    });
  });
}

export function useJSONLoader(url: string) {
  return useLoader(THREE.ObjectLoader as any, url) as THREE.Object3D; // TODO: why is typescript like this?!??
}
useJSONLoader.preload = (path: string) =>
  useLoader.preload(THREE.ObjectLoader as any, path);
export declare namespace useJSONLoader {
  var preload: (path: string) => undefined;
}

export function getObjPathName(obj: THREE.Object3D): string {
  if (obj.userData.smsDigitalTwinScene) return obj.userData.smsDigitalTwinScene;
  const name = obj.userData.smsDigitalTwinName;
  if (!name) return name || "";

  let scene = "";
  while (obj.parent && !scene) {
    obj = obj.parent;
    scene = obj.userData.smsDigitalTwinScene;
  }
  if (!scene) return "";

  return `${scene}.${name}`;
}

/*export function fixPathNames(
    obj: THREE.Object3D, nameAttr: string = 'smsDigitalTwinName', pathNameAttr: string = 'smsDigitalTwinPathName',
    ignore: (obj: THREE.Object3D) => boolean = obj => obj.userData.smsDigitalTwinScene
) {
    // during round-tripping to .gltf some special chars (at least [\./]) get lost and
    // it seems to help @gltf-transform/functions/dedup to replace identical sub-trees
    // by clones (which is harming path names as well)
    // This is reinstating proper path names
    obj.traverse(o => {
        if(o.userData[nameAttr] && !obj.userData.smsDigitalTwinScene) {
            o.userData[pathNameAttr] = getObjPathName(o, nameAttr, ignore);
        }
    });
}*/
