import React, {
  useCallback,
  useRef,
  createRef,
  useState,
  useEffect,
} from "react";
import { useStore } from "./stores/store";
import { useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
//Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Tab,
  Modal,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import NotificationsOffRoundedIcon from "@mui/icons-material/NotificationsOffRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SettingsIcon from "@mui/icons-material/Settings";
//Components
import { useTranslation } from "react-i18next";
import Part3d from "./components/Part3d";
import Maintenance from "./components/Maintenance/Maintenance";
import Alarmreport from "./components/Alarmreport";
import Details from "./components/Details/Details";
import InstallationPlaces from "./components/InstallationPlaces/InstallationPlaces";
import { installationTreeResource } from "../../services/suspenders";
import { Paper } from "@material-ui/core";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import useWindowSize from "../../app/customHooks";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrScanner from "./components/QrScanner";

const useStyles = makeStyles((theme) => ({
  leftGrid: {

    maxWidth: "48%",
    background: "transparent",
  },
  modelAutocompleteContainer: {
    paddingTop: 5,
    paddingLeft:5,
    minWidth: 250,
    maxWidth: "100%",
  },
  autocompleteTextField: {...theme.autocompleteTextField, minWidth:"60%", zIndex:5},

  threeD: {
    width: "100%",
    border: "1px solid transparent",
    borderRadius: 10,
  },
  paper: {
    width: "100%",
    height: "100%",
    background: theme.pageBackgroundSecondary,
  },
  paperLeftBackground: {
    width: "100%",
    height: "100%",
    background: theme.pageBackgroundSecondary,
    boxShadow: "inset 0px 0px 250px #A5A5A5",
  },
  rightGrid: {

    maxWidth: "50%",
    minWidth: "50%",
    background: "transparent",
  },
  button: {
    backgroundColor: theme.interactiveSecondary,
  },

  buttonIcon: {
    color: theme.textInteractiveElement,
  },
  threeDButton: {
    zIndex: 10,
    padding: 6,
    backgroundColor: theme.pageBackground,
    border: `1px solid ${theme.pageBackground}`,
    borderRadius: 10,
  },
  disabledIcon: {
    color: theme.textPlaceholder,
  },
  tabDiv: {
    backgroundColor: "transparent",
    paddingLeft: 10,
    paddingRight: 10,
  },
  tab: {
    "&.MuiTab-root": {
      fontWeight: "normal",
      textTransform: "none",
      backgroundColor: theme.pageBackgroundTertiary,
      "&.MuiTab-textColorPrimary":{
        color:theme.textSecondary
      },
      "&.Mui-selected": {
        fontWeight: "normal",
        textTransform: "none",
        backgroundColor: theme.pageBackgroundSecondary,
        "&.MuiTab-textColorPrimary":{
          color:theme.textPrimary
        }
      },
    },

  },
  innerTab: {
    "&.MuiTab-root": {
      fontWeight: "normal",
      textTransform: "none",
      backgroundColor: theme.pageBackgroundTertiary,
      borderRight: `1px solid ${theme.mediumContrastBorder}`,
      borderLeft: `1px solid ${theme.mediumContrastBorder}`,
      "&.MuiTab-textColorPrimary":{
        color:theme.textSecondary
      },
      "&.Mui-selected": {
        fontWeight: "normal",
        textTransform: "none",
        backgroundColor: theme.pageBackgroundSecondary,
        borderRight: `1px solid ${theme.mediumContrastBorder}`,
        borderLeft: `1px solid ${theme.mediumContrastBorder}`,
        "&.MuiTab-textColorPrimary":{
          color:theme.textPrimary
        }
      },
    },

  },
}));

interface Props {
  partId: string;
}

export default observer(function CasterSetup({ partId }: Props) {
  const { t } = useTranslation();
  const windowHeight = useWindowSize()[0];
  const [mainView, setMainView] = useState<string>("1");
  const [detailsView, setDetailsView] = useState<string>("1");
  const [maintenanceView, setMaintenanceView] = useState<string>("1");
  const [notification, setNotification] = useState<boolean>(false);
  const { casterSetupStore } = useStore();
  const { rightGridHeight, setRightGridHeight } = casterSetupStore;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const rightgrid: any = createRef();
  const tablist: any = useRef();
  const [outlinePosition, setOutlinePosition] = useState<
  ({position:string|undefined,belongs:string|undefined,part:string|undefined}|undefined)>();
const [openQr, setOpenQr] = useState(false);
  //Todo: Can be deleted if windows sizing is correctly working
  window.addEventListener("resize", function () {
    if (rightgrid.current) {
      setRightGridHeight(
        rightgrid.current.offsetHeight - tablist.current.offsetHeight - 30
      );
    }
  });

  const handleMainViewChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setMainView(newValue);
  };

  //Todo: Can be deleted if back/forward method is correctly implemented
  var disableBack =
    location.pathname === "/app/caster/c90afb55-7edd-4003-87ab-00ec73b9d65a";

  const drillDownHome = useCallback(() => {
    positionFromPart(undefined);
    history.push(".");
    setMaintenanceView("1");
    setDetailsView("1");
    setMainView("1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const { installableParts } = installationTreeResource.read();
  const installPlacesAutocompleteArray = Object.values(installableParts).map(
    (x) => ({ label: x.name, id: x.id })
  );

  const goBack = () => {
    setMainView("1");
    positionFromPart(undefined);
    history.goBack();
  };

  const goForward = () => {
    setMainView("1");
    positionFromPart(undefined);
    history.goForward();
  };

  window.onpopstate = function (event: any) {
    setMainView("1");
    positionFromPart(undefined);
  };

  useEffect(() => {
    // Update the document title using the browser API
    setRightGridHeight(
      rightgrid.current.offsetHeight - tablist.current.offsetHeight - 30
    );
  });

  const selectPart = (position: string|undefined) => {

    positionFromPart(position);
    if (position !== undefined && position !== null) setDetailsView("2");
  };

  const handleAlarmClose = () => {
    casterSetupStore.setAlarm({ open: false, id: "", name: "", position: "" });
  };

  const handleQrClose = () => {
   setOpenQr(false)
  };

  const doubleClick = (ids:(string|undefined)[]) => {
  if(ids[0]!== undefined){
    if(installableParts[ids[0]]){
    history.push(`./${installableParts[ids[0]].id}`);}
    else{
      selectPart(ids[1]);
    }
  }
  };

  const positionFromPart = (
    posId: string | undefined,
    part_Id?: any
  ) => {

    if (posId !== (null || undefined)) {

      const part = installableParts[part_Id ? part_Id : partId];
      const installationPositions = part.installationPostions;
      const pos = installationPositions.filter((x) => x.id === posId)[0];


        setOutlinePosition({
         position: posId,
          belongs: pos?.belongsTo.id,
          part: pos?.currentlyInstalled ? pos.currentlyInstalled.id : undefined,
        });

    }
    else {
      setOutlinePosition(undefined);
    }
  };
  useEffect(()=>{
    casterSetupStore.load_eDocs(outlinePosition?(outlinePosition.part?outlinePosition.part:partId):partId);
// eslint-disable-next-line
  },[partId],)

  return (
    /* "visibility": true ? "visible" : "hidden" */
    <>
      <div>

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
          >
            <Grid
              item
              container
              className={classes.leftGrid}
              style={{height:windowHeight-64-10-10}}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Paper className={classes.paper} elevation={0}>
                <Paper className={classes.paperLeftBackground}>
                  <Grid
                    container
                    className={classes.modelAutocompleteContainer}
                  >
                    <Autocomplete
                      className={classes.autocompleteTextField}
                      popupIcon={<KeyboardArrowDown/> }
                      onChange={(event: any, newValue: any) => {
                        if (newValue != null) {
                          history.push(`./${newValue.id}`);
                          setMainView("1");
                          positionFromPart(undefined);
                        }
                      }}
                      value={installPlacesAutocompleteArray.find(
                        (x) => x.id === partId
                      )}
                      disablePortal
                      id="combo-box-demo"
                      options={installPlacesAutocompleteArray}
                      renderInput={(params) => (
                        <TextField

                          {...params}
                          InputLabelProps={{ shrink: false }}
                        />
                      )}
                    />


                   <Button style={{backgroundColor:"#F2F2F2", zIndex:5, borderBottom:"2px solid #BFBFBF" }}
                    onClick={() => {
                      setOpenQr(true);
                    }} >
                     <QrCodeScannerIcon />

                   </Button>
                  </Grid>

                  <Grid
                    container
                    item
                    style={{height:windowHeight-64-10-10, marginTop:-65}}
                    className={classes.threeD}
                    justifyContent="center"
                  >
                    <Part3d

                      partId={partId}
                      outlinePos={outlinePosition}
                      onPositionClick={selectPart}
                      onPositionDClick={doubleClick}
                      setAlarm={casterSetupStore.setAlarm}
                      notification={notification}
                    />
                    <Grid
                      container
                      item
                      direction="row"
                      style={{ marginTop:-65 }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item className={classes.threeDButton}>
                        {notification ? (
                          <Button
                            className={classes.button}
                            variant="contained"
                            onClick={() => {
                              setNotification(false);
                            }}
                          >
                            <NotificationsActiveRoundedIcon
                              className={classes.buttonIcon}
                            />
                          </Button>
                        ) : (
                          <Button
                            className={classes.button}
                            variant="contained"
                            onClick={() => {
                              setNotification(true);
                            }}
                          >
                            <NotificationsOffRoundedIcon
                              className={classes.disabledIcon}
                            />
                          </Button>
                        )}
                      </Grid>

                      <Grid
                        item
                        className={classes.threeDButton}
                        style={{ marginLeft: 5 }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          className={classes.button}
                          variant="contained"
                          onClick={goBack}
                          disabled={disableBack}
                        >
                          <ArrowBackRoundedIcon
                            className={classes.buttonIcon}
                          />
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.button}
                          style={{ marginLeft: 20 }}
                          onClick={drillDownHome}
                        >
                          <HomeRoundedIcon className={classes.buttonIcon} />
                        </Button>
                        <Button
                          className={classes.button}
                          style={{ marginLeft: 20 }}
                          variant="contained"
                          onClick={goForward}
                          disabled={false}
                        >
                          <ArrowForwardRoundedIcon
                            className={classes.buttonIcon}
                          />
                        </Button>
                      </Grid>

                      <Grid
                        item
                        className={classes.threeDButton}
                        style={{ marginLeft: 5 }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          className={classes.button}
                          variant="contained"
                          onClick={() => {}}
                        >
                          <SettingsIcon className={classes.buttonIcon} />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Paper>
            </Grid>

            <Grid item ref={rightgrid} className={classes.rightGrid} style={{height:windowHeight-64-10-10}}>
              <Paper className={classes.paper} elevation={0}>
                <TabContext value={mainView}>

                  <TabList
                    style={{backgroundColor:"#F2F2F2"}}
                    TabIndicatorProps={{ style: { top: "0px"} }}
                    ref={tablist}
                    onChange={handleMainViewChange}

                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label={t("castersetup.tablist.details")}
                      value="1"
                      className={classes.tab}
                    />
                    <Tab
                      label={t("castersetup.tablist.maintenance")}
                      value="2"
                      className={classes.innerTab}
                    />
                    <Tab
                      label={t("castersetup.tablist.installationPlaces")}
                      value="3"
                      className={classes.tab}
                    />
                  </TabList>

                  <div
                    className={classes.tabDiv}
                    style={{ marginTop: 10, height: rightGridHeight }}
                  >
                    <TabPanel style={{ padding: 0 }} value="1">
                      <Details
                        view={detailsView}
                        setDetailsView={setDetailsView}
                        setMainView={setMainView}
                        partId={partId}
                        selectedPart={outlinePosition}
                        positionFromPart={positionFromPart}
                      />
                    </TabPanel>

                    <TabPanel style={{ padding: 0 }} value="2">
                      <TabContext value={maintenanceView}>
                        <Maintenance
                          partId={
                            outlinePosition?.part
                              ? outlinePosition.part
                              : partId
                          }
                          maintenanceView={maintenanceView}
                          setMaintenanceView={setMaintenanceView}
                        />
                      </TabContext>
                    </TabPanel>

                    <TabPanel style={{ padding: 0 }} value="3">
                      <InstallationPlaces
                        setMainView={setMainView}
                        setDetailsView={setDetailsView}
                        position={outlinePosition}
                        setPosition={selectPart}
                        partId={partId}
                      />
                    </TabPanel>
                  </div>
                </TabContext>
              </Paper>
            </Grid>
          </Grid>

      </div>
      <Modal
        open={casterSetupStore.alarm.open}
        onBackdropClick={() => {
          handleAlarmClose();
        }}
      >
        <Alarmreport />
      </Modal>
      <Modal
        open={openQr}
        onBackdropClick={() => {
          handleQrClose();
        }}
      >
       <div >
        <QrScanner setOpenQr={setOpenQr} parts={installPlacesAutocompleteArray}/>

      </div>
      </Modal>
    </>
  );
});
