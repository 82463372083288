import React, { useState, useCallback } from "react";

export const APIMessageContext = React.createContext({
  error: null,
  addError: () => {},
  type: "",
  removeError: () => {},
});

export default function APIMessageProvider({ children }) {
  const [customMessage, setCustomMessage] = useState(null);

  const removeMessage = () => {
    setCustomMessage(null);
  };

  const addError = (message) => setCustomMessage({ message, type: "error" });
  const addSuccess = (message) =>
    setCustomMessage({ message, type: "success" });
  const addWarning = (message) =>
    setCustomMessage({ message, type: "warning" });

  const contextValue = {
    customMessage,
    addError: useCallback((message, type) => addError(message, type), []),
    addSuccess: useCallback((message, type) => addSuccess(message, type), []),
    addWarning: useCallback((message, type) => addWarning(message, type), []),
    removeMessage: useCallback(() => removeMessage(), []),
  };

  return (
    <APIMessageContext.Provider value={contextValue}>
      {children}
    </APIMessageContext.Provider>
  );
}
