import React, { useState } from "react";

//Material UI
import { Box, makeStyles, MenuItem, Select } from "@material-ui/core";
import { ListItem } from "../../app/models/listItem";
import { Relationship } from "../../app/models/relationship";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    alignItems: "center",
  },
  label: {
    flexBasis: "40%",
    maxWidth: "40%",
  },
  item: {
    flexBasis: "60%",
    maxWidth: "60%",
  },
  selectInput: {...theme.selectInput, width:"60%"}
}));

interface Props {
  onSelectedItem: (id: number) => void;
  selectedValue: number;
  items: ListItem[] | Relationship[];
  label: string;
}

export default function SelectSideLabel({
  onSelectedItem,
  selectedValue,
  items,
  label,
}: Props) {
  const classes = useStyles();
  const [value, setValue] = useState(selectedValue ?? -1);

  const handleChange = (e: React.ChangeEvent<{ value: any }>) => {
    e.preventDefault();
    e.stopPropagation();
    const id = e.target.value;
    setValue(id);
    if (id) {
      onSelectedItem(id);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {label}
        </Box>
        <Select

          id="properties"
          style={{borderRadius:0}}
          className={classes.selectInput}
          onChange={handleChange}
          value={value}
        >
          {items.map((item: ListItem) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </div>
  );
}
