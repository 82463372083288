import React from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import { HiStatusOnline } from "react-icons/hi";
import { useTranslation } from "react-i18next";

function getRandomArbitrary(min: any, max: any) {
  return Math.floor(Math.random() * (max - min) + min);
}

export default function SteelQuantity() {
  const { t } = useTranslation();
  const quantity= getRandomArbitrary(100, 1000)




  return (
    <div style={{ height: 120, minWidth: 220, backgroundColor: "#F2F2F2", border: "1px solid #E0E0E0", borderRadius: 5, cursor: "pointer" }}>
      <Grid container item style={{ marginLeft: 10, marginTop: 10, width: "auto" }} direction="row" justifyContent="flex-start" alignItems="center">

        <HiStatusOnline style={{ height: 20, width: 20, color: "green" }} />
        <Typography variant="h5" style={{ marginLeft: 5 }} >{t("castersetup.maintenance.steelQuantity")}</Typography>
      </Grid>
      <Typography variant="h6" style={{ marginLeft: 10, marginTop: 5 }} >
     {quantity} t
     </Typography>
      <Grid container item style={{ marginLeft: 10, width: "auto" }} direction="row" justifyContent="flex-start" alignItems="center">

      </Grid>
    </div>

  )
}