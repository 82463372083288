import axios from "axios";
import { environment } from "../environments/environments";

const baseURL = environment.ASSET_DICTIONARY_API;

//Add a node to the parent node
async function ImportData(payload) {
  try {
    await axios.post(`${baseURL}/Data`, payload).then((response) => {
      console.log(response.data);
    });
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
}

const DataService = {
  ImportData,
};

export default DataService;
