import React, { Suspense, useState } from "react";
import { Grid, Tab, CircularProgress } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EDoc from "./components/eDoc";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import PartHistoryTable from "./components/History";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import JobChart from "./components/JobChart";
import { Typography } from "@material-ui/core";
import TempChart from "./components/Widgets/tempChart";
import SegmentThickness from "./components/Widgets/SegmentThickness";
import SlabThickness from "./components/Widgets/SlabThickness";
import SlabWidth from "./components/Widgets/SlabWidth";
import CastSpeed from "./components/Widgets/CastSpeed";
import WaterPressure from "./components/Widgets/WaterPressure";
import WaterFlow from "./components/Widgets/WaterFlow";
import UpTime from "./components/Widgets/UpTime";
import LaddleQuantity from "./components/Widgets/LaddleQuantity";
import SteelQuantity from "./components/Widgets/SteelQuantity";
import Mileage from "./components/Widgets/Mileage";
const useStyles = makeStyles((theme) => ({
  tab: {
    "&.MuiTab-root": {
      borderBottom: `1px solid ${theme.mediumContrastBorder}`,
      fontWeight: "normal",
      textTransform: "none",

      "&.MuiTab-textColorPrimary": {
        color: theme.textTertiary
      },
      "&.Mui-selected": {
        borderBottom: "0px",
        borderTop: `1px solid ${theme.mediumContrastBorder}`,
        borderLeft: `1px solid ${theme.mediumContrastBorder}`,
        borderRight: `1px solid ${theme.mediumContrastBorder}`,
        fontWeight: "normal",
        textTransform: "none",


        "&.MuiTab-textColorPrimary": {
          color: theme.textPrimary
        },
      },
    },
  },
  tabPanel: {
    "&.MuiTabPanel-root": {
      padding: 5,
    },
  },
}));

interface Props {
  partId: string;
  maintenanceView: string;
  setMaintenanceView: any;
}

export default observer(function Maintenance({
  partId,
  maintenanceView,
  setMaintenanceView,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { casterSetupStore } = useStore();
  const [data, setData] = useState<any>(null);
  const { rightGridHeight } = casterSetupStore;
  const handleMaintenanceViewChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setMaintenanceView(newValue);
  };



  return (
    <>
      <TabContext value={maintenanceView}>
        <Grid container>
          <Grid item xs={12}>
            <TabList
              TabIndicatorProps={{ style: { display: "none" } }}
              onChange={handleMaintenanceViewChange}
              variant="fullWidth"
              aria-label="lab API tabs example"
            >
              <Tab
                label={t("castersetup.maintenance.tablist.history")}
                value="1"
                className={classes.tab}
              />
              <Tab
                label={t("castersetup.maintenance.tablist.documents")}
                value="2"
                className={classes.tab}
              />
              <Tab
                label={t("castersetup.maintenance.tablist.widgets")}
                value="3"
                className={classes.tab}
              />
            </TabList>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                marginTop: 5,
                height: rightGridHeight ? rightGridHeight - 40 : undefined,
                overflow: "auto",
              }}
            >
              <TabPanel className={classes.tabPanel} value="1">
                <Suspense fallback={<CircularProgress />}>
                  <div >
                    <PartHistoryTable jobData={setData} height={rightGridHeight ? (rightGridHeight / 2) - 40 : 400} partId={partId} />
                  </div>
                  <div style={{ height: rightGridHeight ? (rightGridHeight / 2) - 110 : undefined, marginTop: 80, backgroundColor: "#F2F2F2", border: "1px solid #E0E0E0", borderRadius: 5 }}>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold", marginLeft: 10, marginTop: 10 }} >{t("casterSetup.maintenance.jobsInLast6Months")}</Typography>
                    <div style={{ backgroundColor: "white", borderRadius: 5, marginLeft: 10, marginRight: 10, marginTop: 10, minHeight: 150, height: rightGridHeight ? (rightGridHeight / 2) - 170 : undefined }}>
                      <JobChart data={data} />
                    </div>
                  </div>
                </Suspense>
              </TabPanel>
              <TabPanel className={classes.tabPanel} value="2">
                <EDoc partId={partId} />
              </TabPanel>
              <TabPanel className={classes.tabPanel} value="3">
                {(partId !== "c90afb55-7edd-4003-87ab-00ec73b9d65a") ?
                  <>

                    <Grid spacing={1} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Grid item>
                        <TempChart />
                      </Grid>
                      <Grid item>
                        <SteelQuantity />
                      </Grid>
                      <Grid item>
                        <Mileage />
                      </Grid>
                      <Grid item>
                        <SegmentThickness />
                      </Grid>
                      <Grid item>
                        <SlabThickness />
                      </Grid>
                      <Grid item>
                        <SlabWidth />
                      </Grid>
                      <Grid item>
                        <CastSpeed />
                      </Grid>
                      <Grid item>
                        <WaterPressure />
                      </Grid>
                      <Grid item>
                        <WaterFlow />
                      </Grid>
                      <Grid item>
                        <UpTime />
                      </Grid>
                    </Grid>
                  </> :
                  <><Grid spacing={1} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item>
                      <TempChart />
                    </Grid>
                    <Grid item>
                      <SlabThickness />
                    </Grid>
                    <Grid item>
                      <SlabWidth />
                    </Grid>
                    <Grid item>
                      <CastSpeed />
                    </Grid>
                    <Grid item>
                      <LaddleQuantity />
                    </Grid>
                    <Grid item>
                      <SteelQuantity />
                    </Grid>
                  </Grid></>

                }
              </TabPanel>
            </div>
          </Grid>
        </Grid>
      </TabContext>
    </>
  );
});
