/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import Draggable from "react-draggable";
import DataService from "../../../services/dataService";
import AssetContext from "../../../context/asset-context";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useApiMessage from "../../../common/hooks/useAPIMessage";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  button: { ...theme.darkButton, marginLeft: 2 },
  button2: {
    ...theme.darkButton,
    marginLeft: 2,
    color: theme.link,
    backgroundColor: "transparent",
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function FileImportDialog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [overrideData, setOverrideData] = useState(false);
  const assetContext = useContext(AssetContext);
  const { clearSelectedPart, refreshTree } = assetContext;
  const { addError } = useApiMessage();

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
    setSelectedFile(false);
    setOpen(false);
  };

  const uploadFile = async () => {
    if (selectedFile && selectedFile !== "") {
      setLoading(true);
      const data = new FormData();
      data.append("overrideData", overrideData);
      data.append("file", selectedFile[0], selectedFile[0].name);
      const response = await DataService.ImportData(data);
      if (response) {
        addError(response);
      }
      setLoading(false);
      setOpen(false);
      clearSelectedPart();
      refreshTree();
    }
  };

  return (
    <div>
      <Button
        className={classes.button}
        variant="contained"
        onClick={handleClickOpen}
      >
        {t("page.import.button")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {t("page.import.label")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("page.import.context")}</DialogContentText>
          <DialogContentText>
            <input
              accept="application/JSON"
              style={{ display: "none" }}
              id="raised-button-file"
              onChange={(e) => setSelectedFile(e.target.files)}
              type="file"
            />
            <label htmlFor="raised-button-file">
              <Button variant="raised" component="span">
                {t("page.import.button.select")}
              </Button>
            </label>
            {selectedFile && selectedFile.length > 0
              ? selectedFile[0].name
              : null}
          </DialogContentText>
          <DialogContentText>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={overrideData}
                  onChange={(e) => setOverrideData(e.target.checked)}
                />
              }
              label={t("page.import.override.label")}
              labelPlacement="end"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              disabled={loading}
              className={classes.button2}
            >
              {t("button.cancel")}
            </Button>
            <Button
              onClick={uploadFile}
              className={classes.button2}
              color="primary"
              disabled={loading}
            >
              {t("page.import.button.upload")}
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
