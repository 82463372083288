import React from "react";
import GeneralDetails from "./components/GeneralDetails";
import PartDetails from "./components/PartDetails";
import { useStore } from "../../stores/store";
//Material-UI
import { Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tab: {
    "&.MuiTab-root": {
      borderBottom: `1px solid ${theme.mediumContrastBorder}`,
      fontWeight: "normal",
      textTransform: "none",

      "&.MuiTab-textColorPrimary":{
        color: theme.textTertiary
      },
      "&.Mui-selected": {
        borderBottom: "0px",
        borderTop: `1px solid ${theme.mediumContrastBorder}`,
        borderLeft: `1px solid ${theme.mediumContrastBorder}`,
        borderRight: `1px solid ${theme.mediumContrastBorder}`,
        fontWeight: "normal",
        textTransform: "none",

        "&.MuiTab-textColorPrimary":{
          color:theme.textPrimary,
        },
      },
    },
  },
  tabPanel: {
    "&.MuiTabPanel-root": {
      padding: 5,
    },
  },
}));
interface Props {
  partId: string | undefined;
  selectedPart: any;
  positionFromPart: any;
  view: any;
  setDetailsView: any;
  setMainView: any;
}

export default function Details({
  setMainView,
  setDetailsView,
  view,
  partId,
  selectedPart,
  positionFromPart,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { casterSetupStore } = useStore();
  const { rightGridHeight } = casterSetupStore;
  const handleDetailsViewChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setDetailsView(newValue);
  };
  return (
    <TabContext value={view}>
      <TabList
        TabIndicatorProps={{ style: { display: "none" } }}
        onChange={handleDetailsViewChange}
        variant="fullWidth"
        aria-label="lab API tabs example"
      >
        <Tab
          label={t("castersetup.details.tablist.general")}
          value="1"
          className={classes.tab}
        />
        <Tab
          label={t("castersetup.details.tablist.part")}
          value="2"
          className={classes.tab}
        />
      </TabList>
      <div
        style={{
          marginTop: 5,
          height: rightGridHeight ? rightGridHeight - 40 : undefined,
          overflow: "auto",
        }}
      >
        <TabPanel className={classes.tabPanel} value="1">
          <Grid item>
            {partId ? (
              <GeneralDetails
                setMainView={setMainView}
                partId={partId}
                positionFromPart={positionFromPart}
              />
            ) : (
              <Typography>{t("castersetup.details.noPartSelected")}</Typography>
            )}
          </Grid>
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="2">
          <Grid item>
            {selectedPart ? (
              <PartDetails
                setDetailsView={setDetailsView}
                setMainView={setMainView}
                selectedPart={selectedPart}
                positionFromPart={positionFromPart}
              />
            ) : (
              <Typography>{t("castersetup.details.noPartSelected")}</Typography>
            )}
          </Grid>
        </TabPanel>
      </div>
    </TabContext>
  );
}
