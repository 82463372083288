export const colorScheme = [
  "#25CCF7",
  "#c0392b",
  "#9b59b6",
  "#f1c40f",
  "#00d2d3",
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#FD7272",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#54a0ff",
  "#e67e22",
  "#e74c3c",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#7f8c8d",
  "#55efc4",
  "#81ecec",
  "#74b9ff",
  "#a29bfe",
  "#00b894",
  "#00cec9",
  "#0984e3",
  "#6c5ce7",
  "#ffeaa7",
  "#fab1a0",
  "#ff7675",
  "#54a0ff",
  "#fdcb6e",
  "#e17055",
  "#d63031",
  "#feca57",
  "#5f27cd",
  "#fd79a8",
  "#01a3a4",
];
