/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { PresentationContext } from "../../context/presentation-context";

import Agent from "../../services/presentationService";
import { Prepare } from "../../components/ResizeblePanels";

import useApiMessage from "../../common/hooks/useAPIMessage";

// this is used to select cockpit items.
const useStyles = makeStyles({
  container: {
    color: "#BBB",
    borderWidth: "1.5px",
    borderStyle: "solid",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  title: {
    paddingLeft: "15px",
    fontStretch: "bold",
  },
});

const PrepareCockpit = () => {
  const [state, dispatch] = useContext(PresentationContext);
  const { addError, addSuccess } = useApiMessage();
  const history = useHistory();
  const classes = useStyles();
  const [cockPit, setCockPit] = useState(state.currentPresentation);
  const [layoutReady, setLayoutReady] = useState(false);
  const [layout, setLayout] = useState([]);
  const [initialLayout, setInitialLayout] = useState(false);
  const [needSave, setNeedSave] = useState(false);
  const [isPublic, setIsPublic] = useState(
    cockPit ? Boolean(cockPit.public) : 0
  );

  const [selectedItems, setSelectedItems] = useState([]);

  const [loading, setLoading] = useState(false);

  const prepareLayout = useCallback((items) => {
    setLoading(true);
    setLayoutReady(false);
    let preparedLayout = [];
    let index = 0;
    items.forEach((item) => {
      let layoutItem = {
        id: item.id,
        name: item.name,
        visualization_type: item.visualization_type,
        pannelLocation: item.pannelLocation
          ? item.pannelLocation
          : {
              id: item.id,
              i: item.id,
              x: index,
              y: 0,
            },
      };

      index += 4;
      preparedLayout.push(layoutItem);
    });
    const panels = preparedLayout.map((item) => item.pannelLocation);

    setSelectedItems(items);
    setLayoutReady(true);
    setLayout(panels);
    setLoading(false);
  }, []);

  const loadData = useCallback(() => {
    setCockPit(cockPit);
    if (cockPit.pannels) {
      if (cockPit._id) {
        setInitialLayout(true);
        setSelectedItems(
          cockPit.pannels.map((panel) => ({
            id: panel.id,
            name: panel.name,
            visualization_type: panel.visualization_type,
            pannelLocation: panel.pannelLocation,
          }))
        );

        setLayoutReady(true);
      }
    }
  }, [cockPit]);

  const onNeedSave = () => {
    setNeedSave(true);
  };

  useEffect(() => {
    if (!cockPit) return;
    if (cockPit && !layoutReady) {
      prepareLayout(cockPit.pannels);
      loadData();
    } else {
      prepareLayout(cockPit.pannels);
      setInitialLayout(false);
    }
  }, [cockPit, layoutReady, loadData, prepareLayout]);

  const onLayoutChanged = useCallback(
    (layout) => {
      if (loading) return;
      setLayout(layout);
    },
    [layoutReady, loading, loadData, prepareLayout]
  );

  const getPannels = () => {
    const newPannels = [];

    selectedItems.forEach((item) => {
      let pannelLocation = layout.find(
        (currentPannel) => currentPannel.i === item.id
      );

      newPannels.push({
        id: item.id,
        name: item.name,
        visualization_type: item.visualization_type,
        pannelLocation: pannelLocation,
      });
    });

    return newPannels;
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleCancel = () => {
    dispatch({
      type: "SET_CURRENT_PRESENTATION",
      payload: null,
    });
    history.push("/app/cockpits");
  };

  const handleSave = async () => {
    try {
      let result;
      const Pannels = getPannels();
      const presentation = {
        ...cockPit,
        pannels: Pannels,
        status: "ACTIVATED",
        public: String(Boolean(isPublic)),
      };

      if (!cockPit._id) {
        await Agent.Presentations.create(presentation);
      } else {
        await Agent.Presentations.update(presentation);
      }
      setNeedSave(result);
      addSuccess("Cockpit Saved!");
    } catch (error) {
      console.log(error);
      addError("Error on save cockpit!");
    }
  };
  const handleChange = (event) => {
    setIsPublic(Number(event.target.checked));
    setNeedSave(true);
  };

  return (
    <>
      {layoutReady && !loading && (
        <>
          <div className={classes.header}>Manteinance Cockpit</div>
          <div className={classes.title}>Cockpit Name: {cockPit.name}</div>
          <div className={classes.title}>
            <FormControlLabel
              control={
                <Checkbox
                  name="Public"
                  onChange={handleChange}
                  checked={Boolean(isPublic)}
                  inputProps={{ "aria-label": "Public" }}
                />
              }
              label="Public"
            />
          </div>
          <Prepare
            items={cockPit.pannels.map((panel) => ({
              id: panel.id,
              name: panel.name,
              visualization_type: panel.visualization_type,
              pannelLocation: panel.pannelLocation,
            }))}
            onLayoutChange={onLayoutChanged}
            onNeedSave={onNeedSave}
            editable={true}
            setInitialLayout={initialLayout}
            removable={false}
          />
        </>
      )}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" m={2} p={1}>
          <Box p={1}>
            <Button
              color={"secondary"}
              variant="contained"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button color={"primary"} variant="contained" onClick={handleBack}>
              Back
            </Button>
            <Button
              color={"primary"}
              variant="contained"
              onClick={handleSave}
              disabled={!needSave}
            >
              {needSave ? "Save" : "Saved"}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default PrepareCockpit;
