import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@material-ui/core/Typography";
import AssetContext from "../../../../context/asset-context";
import { Agent } from "../../../../app/api/agent";
import { ListItem } from "../../../../app/models/listItem";
import SelectSideLabel from "../../../../components/SelectSideLabel";

import { Relationship } from "../../../../app/models/relationship";
import RelationshipSection from "./RelationshipSection";
import { PartItem } from "../../../../app/models/partitem";
import { AddPartPropertyRequest } from "../../../../app/models/property";
import Attribute from "./Attribute";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },

  });

const useStyles = makeStyles((theme) => ({
  button: { ...theme.darkButton, marginLeft: 2 },
  tableButton: { color: theme.link },
  button2: { ...theme.darkButton, marginLeft: 2, color: theme.link, backgroundColor: "transparent" },
}));


interface Props {
  iconLaunch: boolean;
  selectedPart: PartItem;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AddProperties({ iconLaunch, selectedPart }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const assetContext = useContext(AssetContext);
  const { refreshPartDetails } = assetContext;
  const [selectedProp, setSelectedProp] = useState(-1);
  const [propertyTypes, setPropertyTypes] = useState<ListItem[]>([]);
  const [relationships, setRelationships] = useState<Relationship[]>([]);

  const [relatedPart, setRelatedPart] = useState<PartItem>();
  const [relationshipId, setRelationshipId] = useState(-1);
  //Attributes
  const [attributeName, setAttributeName] = useState("");
  const [attributeValue, setAttributeValue] = useState("");
  //System
  const [systemId, setSystemId] = useState("");
  const [systemDesc, setSystemDesc] = useState("");
  const [selectedSystem, setSelectedSystem] = useState("");

  useEffect(() => {
    (async () => {
      const properties = await Agent.PropertyTypes.list();
      if (properties.length > 0) {
        setPropertyTypes(properties);
      }

      const relationships = await Agent.Relationships.list();
      if (relationships.length > 0) {
        setRelationships(relationships);
      }
    })();
  }, []);

  const handleSave = async () => {
    //TODO: Validations
    const type = relationships.find((x) => x.id === relationshipId)?.rule ?? "";
    let payload;
    switch (type) {
      case "system":
        payload = {
          partId: `${selectedPart.id}`,
          propertyTypeId: selectedProp,
          relationshipId: relationshipId,
          ExternalSystemId: `${selectedSystem}`,
          SystemIdentifier: `${systemId}`,
          Description: `${systemDesc}`,
        };
        break;
      case "install":
      case "install_reverse":
        payload = {
          partId: `${selectedPart.id}`,
          propertyTypeId: selectedProp,
          relationshipId: relationshipId,
          value: `${relatedPart?.name}`,
          relatedPartId: `${relatedPart?.id}`,
        };
        break;
      case "attribute":
        payload = {
          partId: `${selectedPart.id}`,
          propertyTypeId: selectedProp,
          relationshipId: relationshipId,
          value: `${attributeValue}`,
          name: `${attributeName}`,
        };
        break;
      default:
        payload = {
          partId: `${selectedPart.id}`,
          propertyTypeId: selectedProp,
          value: `${attributeValue}`,
          name: `${attributeName}`,
        };
        break;
    }

    if (payload) {
      await Agent.Parts.addProperty(payload as AddPartPropertyRequest).then(
        () => {
          setOpenAlert(true);
          refreshPartDetails();
        }
      );
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setSelectedProp(-1);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handlePropertyChange = useCallback((id: number) => {
    if (id) {
      setSelectedProp(id);
      setRelationshipId(-1);
    }
  }, []);

  const handleRelatedPart = (part: PartItem) => {
    setRelatedPart(part);
  };

  const handleRelationship = (id: number) => {
    setRelationshipId(id);
  };

  //TODO: Need rework if we do translations on data
  function renderBody() {
    const selected = propertyTypes.find((x) => x.id === selectedProp);
    switch (selected?.name) {
      case "RELATIONSHIP":
        return (
          <RelationshipSection
            items={relationships}
            parent={selectedPart.name}
            onRelatedPart={handleRelatedPart}
            onRelationSelect={handleRelationship}
            onNameChange={(e) => {
              setAttributeName(e);
            }}
            onValueChange={(e) => {
              setAttributeValue(e);
            }}
            onSystemId={(e) => {
              setSystemId(e);
            }}
            onSystemDesc={(e) => {
              setSystemDesc(e);
            }}
            onSystemSelect={(e) => {
              setSelectedSystem(e);
            }}
          />
        );
      case "ATTRIBUTE":
        return (
          <Attribute
            onNameChange={(e) => {
              setAttributeName(e);
            }}
            onValueChange={(e) => {
              setAttributeValue(e);
            }}
          />
        );
      default:
        return null;
    }
  }

  function launchButton() {
    if (iconLaunch) {
      return (
        <IconButton
          className={classes.tableButton}
          aria-label="addprop"
          onClick={handleClickOpen}
          disabled={selectedPart.id === ""}
        >
          <PostAddIcon />
        </IconButton>
      );
    } else {
      return (
        <Button
          endIcon={<AddIcon />}
          className={classes.button}
          variant="contained"
          onClick={handleClickOpen}
          disabled={selectedPart.id === ""}
        >
          {t("page.addproperty.label")}
        </Button>
      );
    }
  }

  return (
    <div>
      {launchButton()}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("page.addproperty.label")}
        </DialogTitle>
        <DialogContent dividers>
          <SelectSideLabel
            onSelectedItem={handlePropertyChange}
            selectedValue={selectedProp}
            items={propertyTypes}
            label={t("page.addproperty.type.label")}
          />
          {selectedProp !== -1 && renderBody()}
        </DialogContent>
        <DialogActions>
          <Button className={classes.button2} autoFocus onClick={handleSave} color="primary">
            {t("button.save")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="success" onClose={handleCloseAlert}>
          {t("page.addproperty.alert")}
        </Alert>
      </Snackbar>
    </div>
  );
}
