import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from "react";
//Internal
import AssetTreeView from "./components/AssetTreeView";
import AssetContext from "../../context/asset-context";
//Material-UI
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@mui/material";
import Divider from "@material-ui/core/Divider";
import NodeHeader from "./components/NodeHeader";
//TODO:combine
import { Agent as PlantAgent } from "../../services/plantviewsService.ts";
import PlantviewsList from "./components/PlantviewsList";
import { Agent } from "../../app/api/agent";
import PartSearch from "./components/PartSearch";
import { Typography } from "@material-ui/core";
import useWindowSize from "../../app/customHooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  divider: {
    margin: theme.spacing(1, 0),
  },
  "@global": {
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "rgba(0, 117, 190, 0.08)",
      },
  },
}));

export default function AssetTree() {
  const windowHeight = useWindowSize()[0];
  const ROOTNODE_KEY = "rootNode";
  const SELECTED_PART_KEY = "selectedPart";
  const VIEW_TYPE_KEY = "viewType";
  const DEFAULT_TREE = useMemo(() => ({ id: "", name: "", children: [] }), []);
  const DEFAULT_PART = useMemo(() => ({ id: "", name: "" }), []);
  const classes = useStyles();
  const [rootNode, setRootNode] = useReducer((_previous, node) => {
    localStorage.setItem(ROOTNODE_KEY, JSON.stringify(node));
    return node;
  }, JSON.parse(localStorage.getItem(ROOTNODE_KEY)) || DEFAULT_TREE);
  const [selectedPart, setSelectedPart] = useReducer((_previous, node) => {
    localStorage.setItem(SELECTED_PART_KEY, JSON.stringify(node));
    return node;
  }, JSON.parse(localStorage.getItem(SELECTED_PART_KEY)) || DEFAULT_PART);
  const [viewType, setViewType] = useReducer((_previous, node) => {
    localStorage.setItem(VIEW_TYPE_KEY, JSON.stringify(node));
    return node;
  }, JSON.parse(localStorage.getItem(VIEW_TYPE_KEY)) || -1);

  const { t } = useTranslation();
  const [treeData, setTreeData] = React.useState(DEFAULT_TREE);
  const [displaySection, setDisplaySection] = useState("hide");
  const [relationships, setRelationships] = useState([]);
  const [externalSystems, setExternalSystems] = useState([]);
  const [partDetails, setPartDetails] = useState({
    properties: [],
    systems: [],
  });

  const onSelectPartHandler = (event, id) => {
    if (setSelectedPart.id === id || !event.nativeEvent.target.innerText)
      return;

    const name = event.nativeEvent.target.innerText;
    setSelectedPart({ id: id, name: name });
  };

  const clearSelectedPartHandler = () => {
    setSelectedPart({ id: "", name: "" });
    setDisplaySection("hide");
  };

  const selectPartHandler = (part) => {
    if (setSelectedPart.id === part.id) return;
    setSelectedPart(part);
  };

  const loadPartDetails = useCallback(async () => {
    if (selectedPart.id === "") {
      setPartDetails({ properties: [], systems: [] });
      return;
    }

    const details = await Agent.Parts.details(selectedPart.id);
    if (details !== "") {
      setPartDetails(details);
    } else {
      setPartDetails({ properties: [], systems: [] });
    }
  }, [selectedPart.id]);

  const refreshPartDetailsHandler = async () => {
    loadPartDetails();
  };

  useEffect(() => {
    (async () => await loadPartDetails())();
  }, [loadPartDetails, selectedPart]);

  useEffect(() => {
    (async () => {
      const relList = await Agent.Relationships.list();
      if (relList !== "") {
        setRelationships(relList);
      }
    })();

    (async () => {
      const systems = await Agent.ReferenceData.getSystems();
      if (systems) {
        setExternalSystems(systems);
      }
    })();
  }, []);

  const selectRootHandler = (part) => {
    if (part) {
      setRootNode(part);
    }
  };

  const loadTree = useCallback(async () => {
    let plantview;
    const start = rootNode.name === "" ? "StrandCaster6" : rootNode.name; //TODO:Default??

    if (viewType !== -1) {
      plantview = await PlantAgent.Plantviews.filtered(start, viewType);
    } else {
      plantview = await PlantAgent.Plantviews.all(start);
    }

    if (plantview !== "") {
      setTreeData(plantview);
      if (selectedPart.id === "") {
        setSelectedPart({ id: plantview.id, name: plantview.name });
      }
    } else {
      setSelectedPart(DEFAULT_PART);
      setTreeData(DEFAULT_TREE);
    }
  }, [DEFAULT_PART, DEFAULT_TREE, rootNode, selectedPart.id, viewType]);

  const refreshTreeHandler = async () => {
    loadTree();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    (async () => await loadTree())();
  }, [loadTree, rootNode, viewType]);

  const editHandler = (event) => {
    setDisplaySection(event);
  };

  const handleRelatedPartSelect = (value) => {
    if (value && value?.id) {
      setRootNode(value);
      selectPartHandler(value);
    }
  };

  const renderTree = () => {
    if (treeData.children.length > 0) {
      return <AssetTreeView key={treeData.id} {...treeData}></AssetTreeView>;
    } else {
      return [<div key="stub" />];
    }
  };

  return (
    <AssetContext.Provider
      value={{
        selectedPart: selectedPart,
        clearSelectedPart: clearSelectedPartHandler,
        onSelectPart: onSelectPartHandler,
        selectPart: selectPartHandler,
        rootNode: rootNode,
        treeData: treeData,
        viewType: viewType,
        selectRoot: selectRootHandler,
        editSettings: editHandler,
        refreshPartDetails: refreshPartDetailsHandler,
        refreshTree: refreshTreeHandler,
        displaySection: displaySection,
        partDetails: partDetails,
        relationships: relationships,
        externalSystems: externalSystems,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4} lg={3}>
          <PlantviewsList
            selectedValue={viewType}
            hideLabel={false}
            onSelectedItem={(id) => {
              setViewType(id);
            }}
          />
          <Divider className={classes.divider} />
          <Typography variant="body2" gutterBottom>
            {t("page.plantview.startingNode")}
          </Typography>
          <PartSearch width="100%" onItemSelect={handleRelatedPartSelect} />
          <Divider className={classes.divider} />
          <div
            style={{
              height: windowHeight - 64 - 10 - 48 - 235,
              overflowY: "auto",
            }}
          >
            {renderTree()}
          </div>
        </Grid>
        <Divider
          style={{ height: windowHeight - 64 - 10 - 48 - 20 }}
          orientation="vertical"
        />
        <Grid item xs={12} md={8} lg={8.99}>
          <NodeHeader></NodeHeader>
        </Grid>
      </Grid>
    </AssetContext.Provider>
  );
}
