/* eslint-disable react/prop-types */
import React, { useState, useCallback } from "react";
import { Button } from "@material-ui/core";

import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import { format } from "date-fns";
import DtModal from "../../../../components/Modal";
import { DECJobs } from "../../../../app/models/decJobs";

interface Props {
  jobs: DECJobs[];
}

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70, hide: true },
  { field: "status", headerName: "Status", width: 130 },
  {
    field: "created",
    headerName: "Created",
    type: "date",
    width: 160,
    valueFormatter: (params: GridValueFormatterParams) =>
      format(new Date(String(params.value)), "dd/MM/yyyy HH:mm"),
  },
  { field: "title", headerName: "Title", width: 200 },
];

export default function JobList({ jobs }: Props) {
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSave = useCallback(() => {
    setMessage("Method is not implemented");
    setShowModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <DtModal
        open={showModal}
        contentText={message}
        title={"Alert!"}
        showCancelButton={true}
        showConfirmButton={false}
        closeButtonName="Ok"
        handleClose={handleClose}
      />
      <>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Create Job
        </Button>
        <DataGrid
          rows={jobs}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection={false}
        />
      </>
      ){/* } */}
    </>
  );
}
