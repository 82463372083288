import React, { useContext } from "react";
import AssetContext from "../../../context/asset-context";
import ListProporties from "./ListProperties";
import FileImportDialog from "./FileImport";

//Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//permissions
import PermissionsService from "../../../services/permissionsService";
import { enRoles } from "../../../app/models/permissions";
import AddProperties from "./AddProperty/AddProperty";
import AddPartNew from "./AddEquipment/AddEquipment";
import Plantviews from "./Plantview/Plantview";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: theme.spacing(1),
  },
}));

export default function NodeHeader() {
  const classes = useStyles();
  const assetContext = useContext(AssetContext);
  const { selectedPart } = assetContext;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.header}>
        {PermissionsService.isUserInRole(enRoles.ASSET_MANAGEMENT_ADMIN) && (
          <>
            <FileImportDialog />
            <Plantviews iconLaunch={false} />
            <AddProperties iconLaunch={false} selectedPart={selectedPart} />
            <AddPartNew iconLaunch={false} />
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <ListProporties></ListProporties>
      </Grid>
    </Grid>
  );
}
