import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router-dom";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {

    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    borderRadius: 5,
    backgroundColor: "white",
    maxWidth: 700,
  },
  item: {
    padding: 5,
    width: "100%",
  },
  headLine: {
    fontSize: "19px",
  },
  headLineValue: {
    fontSize: "19px",
    wordWrap: "break-word"

  },
  infoHeadLine: {
    fontSize: "17px",
  },
  infoHeadLineValue: {
    fontSize: "17px",
    wordWrap: "break-word"

  },
  textarea: {
    fontFamily: "Roboto",
    backgroundColor: "transparent",
    width: "100%",
    height: 350,
    resize: "none",
    fontSize: 17,
    marginTop: -10,
  },
  button: theme.darkButton,
  qr: {
    borderRadius: "16px"
  }
}));

export default function QrScanner({ parts, setOpenQr }: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [borderColor, setBorderColor] = useState("#d3d3d380")
  const [warningVisible, setWarningV] = useState(false)
  const [okVisible, setOkV] = useState(false)
  const [scannedID, setScannedId] = useState("")

  function checkCode(code: string | null) {
    if (code !== null) {
      let findPart = parts.filter((part: any) => part.id === code)
      if (findPart.length > 0) {
        setBorderColor("green")
        setScannedId(findPart[0].label)
        setOkV(true)
        setTimeout(() => {
          history.push(`./${code}`);
          setWarningV(false)
          setOpenQr(false)
          setScannedId("")
          setOkV(false)
        }, 1000);



      }
      else {
        setBorderColor("red")
        setWarningV(true)
      }
    }
    else if (borderColor !== "#d3d3d380") {
      setBorderColor("#d3d3d380")
      setWarningV(false)
    }
  }

  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >

      <Grid item container style={{ border: `4px solid transparent`, minWidth: "100%" }}>
      <Grid container style={{
  zIndex: 50, top: 54, left: 54, position: "absolute", height: 592, width: 592, backgroundColor: "transparent",
  border: `3px solid ${borderColor}`, boxShadow: "rgba(15, 15, 15, 0.6) 0px 0px 0px 50px"
}} direction="column"
  alignItems="center"
  justifyContent="center"  >
{warningVisible?
 <Grid container item style={{width:150, backgroundColor: "#FFFFFF70", padding:5, borderRadius:5 }}
 direction="column"
 alignItems="center"
 justifyContent="center"

 >
   <WarningAmberRoundedIcon sx={{ fontSize: 60, color:"red", alignSelf:"center" }}/>
<Typography variant="h6" style={{color:"red"}}>Unknown ID</Typography>
 </Grid>

:<></>
}
{okVisible?
 <Grid container item style={{minWidth:150,maxWidth:300, backgroundColor: "#FFFFFF70", padding:5, borderRadius:5 }}
 direction="column"
 alignItems="center"
 justifyContent="center"

 >
  <CircularProgress sx={{color:"green"}}/>
<Typography variant="h6" style={{color:"green"}}>Go to {scannedID}</Typography>
 </Grid>

:<></>
}
</Grid>

        <QrReader
          delay={300}
          style={{ width: "100%" }}
          showViewFinder={false}
          className={classes.qr}
          onError={() => console.log("x")}
          onScan={(result) => { checkCode(result) }}
        />
      </Grid>
      <Grid
        style={{ padding: 5, width: "100%" }}
        item
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Button variant="contained" className={classes.button} onClick={() => setOpenQr(false)} >
          {t("button.close")}
        </Button>
      </Grid>
    </Grid>
  );
};
