import React, { useEffect } from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CockPitTreeNode } from "../../../app/models/cockPitTreeNode";

var helpArray: any = [];
interface Props {
  initialValue: string;
  nodes: CockPitTreeNode[];
  handleChangePart: (node: CockPitTreeNode) => CockPitTreeNode;
}
const addParent = (object: any) => {
  if (object.length > 0) {
    object.forEach((Element: any) => {
      if (Element.children) {
        Element.children.forEach((Element2: any) => {
          Element2.parent = [Element.id];
          helpArray.push(Element2);
          addParent(Element2);
        });
      }
    });
  } else if (object.children) {
    object.children.forEach((Element: any) => {
      Element.parent = [...object.parent];
      Element.parent.push(object.id);
      helpArray.push(Element);
      addParent(Element);
    });
  }
};
export default function DashboarTree({
  initialValue,
  handleChangePart,
  nodes,
}: Props) {
  // treeview config
  if (nodes.length !== 0) {
    addParent(nodes);
  }

  const [expanded, setExpanded] = React.useState<string[]>(
    initialValue ? [initialValue] : []
  );
  const [selected, setSelected] = React.useState<string[]>(
    initialValue ? [initialValue] : []
  );

  const handleToggle = (e: React.ChangeEvent<any>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (e: React.ChangeEvent<any>, nodeIds: string[]) => {
    setSelected(nodeIds);
  };
  // end of config

  const renderTree = (node: CockPitTreeNode) => (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      title={node.text}
      label={node.text}
      onLabelClick={() => handleChangePart(node)}
    >
      {Array.isArray(node.children)
        ? node.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  useEffect(() => {
    if (nodes.length) {
      //update selected nodes after loading nodes recursivelly

      if (helpArray.find((e: any) => e.id === initialValue)) {
        setExpanded(helpArray.find((e: any) => e.id === initialValue).parent);
        setSelected(helpArray.find((e: any) => e.id === initialValue).id);
      }
      // setExpanded(expanded);
    }
  }, [nodes, initialValue]);

  return (
    <>
      {nodes && nodes.length > 0 && (
        <TreeView
          aria-label="controlled"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {nodes.map((node) => renderTree(node))}
        </TreeView>
      )}
    </>
  );
}
