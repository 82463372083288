/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Material UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";

// unique ID for panel
import { v4 as uuidv4 } from "uuid";

//internals
import Agent from "../../services/presentationService";
import useApiMessage from "../../common/hooks/useAPIMessage";
import useProgressBar from "../../common/hooks/useProgressBar";
import { AvaiableCharts } from "./components/availableCharts";
import { Prepare } from "../../components/ResizeblePanels";
import PanelContent from "./components/panelContent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    minHeight: 500,
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "84vh",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  gridItems: {
    paddingTop: "30px",
    justifyContent: "space-between",
    paddingLeft: "30px",
  },
  legend: {
    fontSize: 9,
    width: "100%",
    textAlign: "center",
  },
  image: {
    width: 300,
    cursor: "pointer",
  },
  panels: {
    minHeight: 500,
    minWidth: 1200,
  },
}));

const PrepareDashboard = ({ current }) => {
  const [isValid, setIsvalid] = useState(false);
  const { showProgressBar, hideProgressBar } = useProgressBar();
  const { addError, addSuccess } = useApiMessage();
  const [layoutReady, setLayoutReady] = useState(false);
  const [isPublic, setIsPublic] = useState(
    current ? Boolean(current.public) : 0
  );
  const [needSave, setNeedSave] = useState(false);
  const [initialLayout, setInitialLayout] = useState(false);
  const [layout, setLayout] = useState([]);
  const [editable, setEditable] = useState(true);
  const [dashboard, setDashboard] = useState(current ?? null);

  const classes = useStyles();

  const getPanelContent = (object) => {
    let pannelContent = {};
    for (const key of Object.keys(object)) {
      const val = object[key];
      pannelContent[key] = String(val);
    }
    return pannelContent;
  };

  const getPannels = () => {
    const newPannels = [];

    dashboard.pannels.forEach((item) => {
      let pannelLocation = layout.find(
        (currentPannel) => currentPannel.i === item.id
      );

      newPannels.push({
        id: item.id,
        name: item.name,
        visualization_type: item.visualization_type,
        pannelContent: item.pannelContent,
        pannelLocation: pannelLocation,
      });
    });

    return newPannels;
  };

  const loadData = () => {
    if (current && !layoutReady) {
      if (current.pannels) {
        if (current._id) {
          setInitialLayout(true);
          setLayoutReady(true);
        } else {
          setInitialLayout(false);
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      loadData();
    };
  }, [current, layoutReady]);

  const history = useHistory();

  const onNeedSave = () => {
    setNeedSave(true);
  };

  const handleSuccess = (result) => {
    addSuccess("Dashboard Saved!");
    setIsvalid(false);
    setNeedSave(false);
    setEditable(true);
    setNeedSave(!result);
    hideProgressBar();
  };

  const handleError = (error) => {
    hideProgressBar();
    addError("Error on saving the Dashboard!");
    setIsvalid(false);
    setNeedSave(true);
  };

  const handleSave = async () => {
    showProgressBar();
    const pannels = getPannels();
    // save or update
    if (dashboard._id) {
      await Agent.Presentations.update({
        ...dashboard,
        pannels,
        Public: String(Boolean(isPublic)),
      })
        .then((result) => {
          handleSuccess(result);
        })
        .catch((error) => {
          console.log("Error on presentation save");
          handleError(error);
          return;
        });
    } else {
      await Agent.Presentations.create({
        ...dashboard,
        pannels,
        Public: String(Boolean(isPublic)),
      })
        .then((result) => {
          handleSuccess(result);
        })
        .catch((error) => {
          console.log("Error on presentation save");
          handleError(error);
          return;
        });
    }
  };

  const handleTextChange = (event) => {
    setDashboard({ ...dashboard, name: event.target.value ?? "" });
    setNeedSave(true);
  };

  const updatePanelContent = (panelContent) => {
    const item = dashboard.pannels.find(
      (item) => item.id === panelContent.pannelId
    );
    const updatedItem = {
      id: item.id,
      name: panelContent.title,
      visualization_type: item.visualization_type,
      pannelLocation: item.pannelLocation,
      pannelContent: panelContent,
      content: () =>
        renderContent(item, panelContent.pannelId, item.pannelContent),
    };

    const filteredList = dashboard.pannels.filter(
      (filteredItem) => filteredItem.id !== updatedItem.id
    );
    filteredList.push(updatedItem);
    setDashboard({ ...dashboard, pannels: filteredList });
  };

  useEffect(() => {
    setIsvalid(
      !!dashboard.name && dashboard.name !== "" && dashboard.pannels.length > 0
    );
  }, [dashboard.name, dashboard.pannels]);

  //the content of panel, must update property panelContent on SelectedItems
  const onAddQuery = (panel) => {
    let pannelContent = getPanelContent(panel);
    const item = dashboard.pannelsContent.find(
      (pc) => pc.pannelId === panel.pannelId
    );
    if (!item) {
      dashboard.pannelsContent.push(pannelContent);

      setDashboard(dashboard);
    } else {
      // filter other and insert again what was changed
      // updatePannel on Dashboard
      const filteredList = dashboard.pannelsContent.filter(
        (i) => i.pannelId !== panel.pannelId
      );
      filteredList.push(pannelContent);
      setDashboard({ ...dashboard, pannelsContent: filteredList });
    }
    setEditable(true);
    setNeedSave(true);

    updatePanelContent(pannelContent);
  };
  const handleEditable = (editable) => {
    setEditable(editable);
  };

  const renderContent = (chartType, pannelId, content) => {
    return (
      <PanelContent
        chartType={chartType}
        pannelId={pannelId}
        content={content}
        onAddQuery={onAddQuery}
        handleEditable={handleEditable}
      />
    );
  };

  const addItem = (item) => {
    const uuid_name = uuidv4();
    const newitems = [
      ...dashboard.pannels,
      {
        id: uuid_name,
        name: item,
        visualization_type: item,
        pannelLocation: item.pannelLocation,
        pannelContent: item.pannelContent,
        content: () => renderContent(item, uuid_name, item.pannelContent),
      },
    ];

    setDashboard({ ...dashboard, pannels: newitems });
    setEditable(true);
  };

  const goToList = () => {
    history.push("/app/dashboards");
  };

  const onItemRemoved = (id) => {
    const remainsItens = dashboard.pannels.filter((item) => item.id !== id);
    setDashboard({ ...dashboard, pannels: remainsItens });

    setNeedSave(true);
  };

  const onLayoutChange = (layout) => {
    setLayout(layout);
    setNeedSave(true);
  };
  const handleChange = (event) => {
    setIsPublic(Number(event.target.checked));
    setNeedSave(true);
  };

  return (
    <>
      <Container maxWidth="lg" className={classes.header}>
        Maintenance Dashboard
      </Container>
      <Container maxWidth="lg" className={classes.header}>
        Name of Dashboard:
        <input
          type="text"
          value={dashboard.name}
          placeholder={"Type the dashboard name"}
          onChange={handleTextChange}
        />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                name="Public"
                onChange={handleChange}
                checked={Boolean(isPublic)}
                inputProps={{ "aria-label": "Public" }}
              />
            }
            label="Public"
          />
        </div>
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1} className={classes.panels}>
          <Grid item xs={12} md={4} lg={6}>
            <Prepare
              presentation={dashboard}
              items={dashboard.pannels.map((panel) => ({
                id: panel.id,
                name: panel.name,
                visualization_type: panel.visualization_type,
                pannelLocation: panel.pannelLocation,
                panelContent: panel.pannelContent,
                content: () =>
                  renderContent(
                    panel.visualization_type,
                    panel.id,
                    panel.pannelContent
                  ),
              }))}
              onItemRemoved={onItemRemoved}
              onLayoutChange={onLayoutChange}
              onNeedSave={onNeedSave}
              setInitialLayout={initialLayout}
              editable={editable}
            />
          </Grid>
        </Grid>
        <Grid>
          <AvaiableCharts onSelect={addItem} />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" m={2} p={1}>
            <Box p={1}>
              <Button color={"primary"} variant="contained" onClick={goToList}>
                Back
              </Button>
              <Button
                color={"primary"}
                variant="contained"
                onClick={handleSave}
                disabled={!isValid}
              >
                {needSave ? "Save" : "Saved"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default PrepareDashboard;
