import React from "react";
import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid red",
    borderRadius: 5,
    backgroundColor: "white",
    maxWidth: 700,
  },
  item: {
    padding: 5,
    width: "100%",
  },
  headLine: {
    fontSize: "19px",
  },
  headLineValue: {
    fontSize: "19px",
    wordWrap: "break-word"

  },
  infoHeadLine: {
    fontSize: "17px",
  },
  infoHeadLineValue: {
    fontSize: "17px",
    wordWrap: "break-word"

  },
  textarea: {
    fontFamily: "Roboto",
    backgroundColor: "transparent",
    width: "100%",
    height: 350,
    resize: "none",
    fontSize: 17,
    marginTop: -10,
  },
  button: theme.darkButton
}));

export default observer(function Alarmreport(){
  const { casterSetupStore } = useStore();
  const { t } = useTranslation();
  const { alarm } = casterSetupStore;
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <h1>{t("castersetup.alarmReport.alarmReport")}</h1>
      <Grid
        className={classes.item}
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <p className={classes.headLine}>{t("castersetup.alarmReport.partName")}:</p>
        </Grid>
        <Grid item xs={9}>
          <p className={classes.headLineValue}>{`${alarm.name}`}</p>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: -40 }}
        className={classes.item}
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <p className={classes.infoHeadLine}>{t("castersetup.alarmReport.partId")}:</p>
        </Grid>
        <Grid item xs={9}>
          <p className={classes.infoHeadLineValue}>{`${alarm.id}`}</p>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: -30 }}
        className={classes.item}
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <p className={classes.infoHeadLine}>{t("castersetup.alarmReport.installedAt")}:</p>
        </Grid>
        <Grid item xs={9}>
          <p className={classes.infoHeadLineValue}>{`${alarm.position}`}</p>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: -30 }}
        className={classes.item}
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <p className={classes.infoHeadLine}>{t("castersetup.alarmReport.alarmCode")}:</p>
        </Grid>
        <Grid item xs={9}>
          <p className={classes.infoHeadLineValue}>
            ad605a24-5215-11ec-bf63-0242ac130002
          </p>
        </Grid>
      </Grid>
      <Grid
        className={classes.item}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={0.1}
      >
        <p className={classes.infoHeadLine}>{t("castersetup.alarmReport.alarmText")}:</p>
        <textarea
          className={classes.textarea}
          readOnly={true}
          spellCheck="false"
          defaultValue="Reason for alarm"
        />
      </Grid>
      <Grid
          style={{ padding: 5, width: "100%"}}
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-end"
        >
         <Button variant="contained" className={classes.button} onClick={()=>casterSetupStore.setAlarm({ open: false, id: "", name: "", position: "" })} >
            {t("button.close")}
          </Button>
          </Grid>
    </Grid>
  );
});
