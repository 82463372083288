import React, { useCallback, useEffect, useState } from "react";

import { PresentationList } from "../../components/Presentation";
import useProgressBar from "../../common/hooks/useProgressBar";

import Agent from "../../services/presentationService";
import {
  Presentation,
  PresentationContentType,
} from "../../app/models/presentation";

import PermissionsService from "../../services/permissionsService";
import { enPermission, enRoles } from "../../app/models/permissions";


const CockPitsSetup = () => {
  const [cockPits, setCockPits] = useState<Presentation[]>([]);
  const { showProgressBar, hideProgressBar } = useProgressBar();

  const loadData = useCallback(async () => {
    showProgressBar();
    let result: Presentation[] = [];
    result = await Agent.Presentations.list(PresentationContentType.Cockpit);
    setCockPits(result);
    hideProgressBar();
  }, [hideProgressBar, showProgressBar]);
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [hideProgressBar, loadData, showProgressBar]);

  const onDeleteItem = async (id: string) => {
    await Agent.Presentations.delete(id);
    loadData();
  };

  return (
    <PresentationList
      presentations={cockPits}
      canEdit={
        PermissionsService.isUserInRole(enRoles.COCKPITS_ADMIN) ||
        PermissionsService.hasPermission(enPermission.COCKPITS_WRITE)
      }
      canRun={
        PermissionsService.isUserInRole(enRoles.COCKPITS_ADMIN) ||
        PermissionsService.hasPermission(enPermission.COCKPITS_READ)
      }
      onDeleteItem={onDeleteItem}
      canDelete={PermissionsService.isAdmin()}
    />
  );
};

export default CockPitsSetup;
