import { createContext, useContext } from "react";
import CasterSetupStore from "./CasterSetupStore";

interface Store {
  casterSetupStore: CasterSetupStore;
}

export const store: Store = {
  casterSetupStore: new CasterSetupStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
