import React from "react";
// Material UI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CardItem from "./carditem";
import panels from "./panels/defaultPanels";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    minHeight: 500,
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  gridItems: {
    paddingTop: "20px",
    justifyContent: "space-between",
    paddingLeft: "20px",
  },
}));

export default function SelectPanels(props) {
  const { showItems, handlerItemChecked, checkedItems } = props;

  const classes = useStyles();

  return (
    <>
      <Paper>Available panels for your Cockpit</Paper>
      <Grid key={"pannels"} container spacing={1}>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            {showItems === true &&
              panels[0].children.map((item) => {
                return (
                  <div key={item.id} className={classes.gridItems}>
                    <CardItem
                      cockpitItem={item}
                      selectable={true}
                      defaultCheck={
                        checkedItems && checkedItems.length
                          ? checkedItems.some(
                              (checkedItem) => checkedItem === item.id
                            )
                          : false
                      }
                      handlerChecked={handlerItemChecked}
                    />
                  </div>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
