import React, { useEffect } from "react";
import useProgressBar from "../../../../common/hooks/useProgressBar";
import { PartItem } from "../../../../app/models/partitem";
//Material-UI
import Grid from "@material-ui/core/Grid";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@material-ui/core";
import { Agent } from "../../../../app/api/agent";
import AddProperties from "../AddProperty/AddProperty";
import { enPermission } from "../../../../app/models/permissions";
import PermissionsService from "../../../../services/permissionsService";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import useWindowSize from "../../../../app/customHooks";

const useStyles = makeStyles((theme) => ({
  dataGrid: theme.dataGrid,

  fixedHeight: {
    minHeight: window.innerHeight - 64 - 10 - 50,
    maxHeight: window.innerHeight - 64 - 10 - 50,
    backgroundColor: "red",
  },
  tableButton: { color: theme.link }
}));
export default function EquipmentList() {
  const { t } = useTranslation();
  const windowHeight = useWindowSize()[0];
  const classes = useStyles();
  const { showProgressBar, hideProgressBar } = useProgressBar();
  const [parts, setParts] = React.useState(new Map<string, PartItem>());

  useEffect(() => {
    //TODO: Do not always load
    (async () => {
      showProgressBar();
      const result = await Agent.Parts.list("");
      result.forEach((part) => {
        parts.set(part.id, part);
      });
      hideProgressBar();
    })();
  }, [hideProgressBar, parts, showProgressBar]);

  const handleDeleteProperty = async (id: string) => {
    await Agent.Parts.delete(id).then(() => {
      setParts((prev) => {
        const newState = new Map(prev);
        newState.delete(id);
        return newState;
      });
    });
  };

  const renderDetailsButton = (params: GridCellParams) => {
    return PermissionsService.hasPermission(
      enPermission.ASSET_MANAGEMENT_WRITE
    ) ? (
      <>
        <AddProperties
          iconLaunch={true}
          selectedPart={params.row as PartItem}
        />
        <IconButton
          aria-label="delete"
          className={classes.tableButton}
          onClick={() => {
            handleDeleteProperty(params.id.toString());
          }}
        >
          <DeleteIcon />
        </IconButton>
      </>
    ) : (
      <></>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("grid.label.id")}`,
      sortable: false,
      flex: 0.5,
    },
    { field: "name", headerName: `${t("grid.label.name")}`, flex: 0.5 },
    { field: "remarks", headerName: `${t("grid.label.remarks")}`, flex: 1 },
    {
      field: "action",
      headerName: `${t("grid.label.action")}`,
      renderCell: (params: GridCellParams) => renderDetailsButton(params),
    },
  ];

  return (

    <Grid container style={{ overflowY: "auto", maxHeight: windowHeight - 64 - 10 - 48 - 75 }}>
      <DataGrid
        className={classes.dataGrid}
        rows={Array.from(parts.values())}
        columns={columns}
        pageSize={15}
        density="compact"
        autoHeight={true}
      />
    </Grid>

  );
}
