/* eslint-disable react/display-name */
import React from "react";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@material-ui/core/Button";
import { changeHistoryResource } from "../../../../../services/suspenders";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const useStyles = makeStyles((theme) => ({
  dataGrid: theme.dataGrid,

  button: {
    textTransform: "none",
    fontWeight:"normal"
  },
  gridDiv: {
    height: 400,
    width: "100%",
  },
}));
interface Props {
  positionId: string;
  setMainView: any;
  setDetailsView: any;
}

export default function PositionHistoryTable({
  positionId,
  setMainView,
  setDetailsView,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const columns = [
    { field: "id", hide: true },
    { field: "date", headerName: t("grid.label.date"), width: 150 },
    {
      field: "partName",
      headerName: t("grid.label.part"),
      minwidth: 300,
      flex: 1,
    },
    /*{ field: "description", headerName: "Installation Position", width: 400 },*/
    {
      field: "action",
      headerName: t("grid.label.action"),
      sortable: false,
      width: 90,
      disableClickEventBubbling: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Button
              color="primary"
              startIcon={<VisibilityOutlinedIcon/>}
              className={classes.button}
              onClick={(event) => {
                history.push(`../caster/${cellValues.id}`);
                setMainView("1");
                setDetailsView("1");
              }}
            >

              {t("grid.action.show")}
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <div className={classes.gridDiv}>
      <DataGrid
        className={classes.dataGrid}
        rows={changeHistoryResource.preload(positionId).read()}
        columns={columns}
        disableColumnMenu={true}
        disableSelectionOnClick
      />
    </div>
  );
}
