import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import { HiStatusOnline } from "react-icons/hi";
import { useTranslation } from "react-i18next";

function getRandomArbitrary(min: any, max: any) {
  return (Math.random() * (max - min) + min);
}

export default function CastSpeed() {
  const { t } = useTranslation();
  const [currentTemp, setTemp] = useState(1)
  const [min, setMin] = useState(0.9)
  const [max, setMax] = useState(1.1)

  useEffect(() => {
    const interval = setInterval(() => {

      let newvalue = currentTemp+getRandomArbitrary(-1/100, 1/100)

      if ((newvalue) > max) {
        setMax(Math.round(newvalue * 100) / 100)
      }
      else if (newvalue < min) {
        setMin(Math.round(newvalue * 100) / 100)
      }
      setTemp(newvalue)
    }
      , 5000);
    return () => clearInterval(interval);

  }, [max,min,currentTemp]);

  return (
    <div style={{ height: 120, minWidth: 220, backgroundColor: "#F2F2F2", border: "1px solid #E0E0E0", borderRadius: 5, cursor:"pointer" }}>
      <Grid container item style={{ marginLeft: 10, marginTop: 10, width: "auto" }} direction="row" justifyContent="flex-start" alignItems="center">

        <HiStatusOnline style={{height: 20, width: 20, color:"green"}}/>
        <Typography variant="h5" style={{ marginLeft: 5 }} >{t("castersetup.maintenance.castingSpeed")}</Typography>
      </Grid>
      <Typography variant="h6" style={{ marginLeft: 10, marginTop: 5 }} >{Math.floor(currentTemp * 100) / 100} m/min</Typography>
      <Grid container item style={{ marginLeft: 10, width: "auto" }} direction="row" justifyContent="flex-start" alignItems="center">
        <Typography variant="caption">Min: {min} m/min</Typography>
        <Typography variant="caption" style={{ marginLeft: 5 }} >Max: {max} m/min</Typography>
      </Grid>
    </div>

  )
}