import React, { useContext, useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, MenuItem, Select } from "@material-ui/core";
import { ListItem } from "../../../../app/models/listItem";
import PlantviewsList from "../PlantviewsList";
import { Agent } from "../../../../app/api/agent";
import { Relationship } from "../../../../app/models/relationship";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ItemList from "./ItemList";
import AssetContext from "../../../../context/asset-context";
import {
  AddViewRelationshipRequest,
  ViewRelationship,
} from "../../../../app/models/plantview";
import { useTranslation } from "react-i18next";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    alignItems: "center",
  },
  label: {
    flexBasis: "40%",
    maxWidth: "40%",
  },
  item: {
    flexBasis: "60%",
    maxWidth: "60%",
  },
  listItem: {
    flexBasis: "100%",
    maxWidth: "100%",
  },
  itemShort: {
    ...theme.selectInput,
    flexBasis: "50%",
    maxWidth: "50%",
  },

  iconButton: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function PlantviewBody() {
  const { t } = useTranslation();
  const classes = useStyles();
  const assetContext = useContext(AssetContext);
  const { viewType } = assetContext;
  const [plantview, setPlantView] = useState(viewType ?? -1);
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [filtered, setFiltered] = useState<Relationship[]>([]);
  const [selectedRel, setSelectedRel] = useState(-1);
  const [plantTypes, setPlantTypes] = useState<ViewRelationship[]>([]);

  const loadPlantRelationships = useCallback(
    async (id: number) => {
      const list = await Agent.Plantviews.relationships(id);
      if (list) {
        setPlantTypes(list);

        if (relationships?.length > 0) {
          const temp = list.map((x) => x.name);
          const filter = relationships?.filter((a) => !temp.includes(a.name));
          setFiltered(filter);
        }
      }
    },
    [relationships]
  );

  useEffect(() => {
    (async () => {
      const list = await Agent.Relationships.list();
      if (list) {
        setRelationships(list);
      }
      loadPlantRelationships(plantview);
    })();
  }, [loadPlantRelationships, plantview]);

  useEffect(() => {
    if (relationships?.length > 0) {
      const temp = plantTypes.map((x) => x.name);
      const filter = relationships?.filter((a) => !temp.includes(a.name));
      setFiltered(filter);
    }
  }, [plantTypes, relationships]);

  const handleChange = (e: React.ChangeEvent<{ value: any }>) => {
    e.preventDefault();
    e.stopPropagation();
    const id = e.target.value;
    setSelectedRel(id);
  };

  const handlePlanttypeChange = (id: number) => {
    setPlantView(id);
    loadPlantRelationships(id);
  };

  const onAddItem = async () => {
    const payload = {
      viewId: `${plantview}`,
      relationshipId: selectedRel,
    };

    if (payload) {
      await Agent.Plantviews.addRelationship(
        payload as AddViewRelationshipRequest
      ).then(() => {
        loadPlantRelationships(plantview);
      });
    }
  };

  const handleDeleteItem = (id: number) => {
    const list = plantTypes?.filter((x) => x.id !== id);
    setPlantTypes(list);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.plantview.plantview.label")}:
        </Box>
        <PlantviewsList
          width="60%"
          hideLabel={true}
          selectedValue={plantview}
          onSelectedItem={(id) => {
            handlePlanttypeChange(id);
          }}
        />
      </Box>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.plantview.relationship.label")}:
        </Box>
        <Select
          id="Relationship"
          IconComponent={KeyboardArrowDown}
          style={{ borderRadius: 0 }}
          className={classes.itemShort}
          onChange={handleChange}
          value={selectedRel}
        >
          {filtered.map((item: ListItem) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <IconButton
          aria-label="add"
          className={classes.iconButton}
          onClick={onAddItem}
          disabled={selectedRel === -1}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        <ItemList items={plantTypes} onDeleteItem={handleDeleteItem} />
      </Box>
    </div>
  );
}
