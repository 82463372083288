import React, { useState, useEffect, useCallback } from "react";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";

//internals charts
import LineChart from "./availableCharts/line";
import PolarChart from "./availableCharts/polar";
import BarChart from "./availableCharts/bar";
import HorizontalChart from "./availableCharts/horizontal";
import DoughnutChart from "./availableCharts/doughnut";
import PieChart from "./availableCharts/pie";
import Gauge from "./availableCharts/gauge";
import defaultData from "../../../components/Presentation/samplesData.json";

//internals
import GeniusCMService from "../../../services/geniusCmService";
import DashboarTree from "./treeView";
import { DatesFilter } from "../../../components/DateUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  form: {
    marginTop: 30,
  },
  selectEmpty: {
    minWidth: 120,
    marginTop: theme.spacing(1),
  },
  combobox: {
    overflow: "hidden",
    overflowY: "scroll",
    maxHeight: 500,
    minHeight: 500,
  },
  chart: {
    maxHeight: 380,
    maxWidth: 700,
  },
}));

export default function DataSourceConfiguration({
  pannelId,
  type,
  onChange,
  panelContent,
  showSelectedDefaults,
}) {
  const classes = useStyles();
  const [nodes, setNodes] = useState([]);
  const [options, setOptions] = useState({});

  const [selfContent, setSelfContent] = useState({
    ...panelContent,
    pannelId,
  });
  const [initialValue] = useState(selfContent.partId);

  const loadData = useCallback(async () => {
    let nodes = await GeniusCMService.getNodes();
    setNodes(nodes);
  }, []);

  const updatedefaultDataProps = useCallback(() => {
    const options = {
      title: {
        display: true,
        text: `${
          selfContent.alias ? selfContent.alias : selfContent.label ?? ""
        } ${selfContent.unit ? `- in ${selfContent.unit}` : ""}`,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            gridLines: {
              drawOnArea: false,
            },
            ticks: {
              stepSize: selfContent.scale ?? 20,
              suggestedMin: selfContent.ymin ?? 0,
              suggestedMax: selfContent.ymax ?? 300,
            },
            beginAtZero: true,
          },
        ],
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
          },
        ],
      },
    };
    setOptions(options);
  }, [
    selfContent.alias,
    selfContent.label,
    selfContent.scale,
    selfContent.unit,
    selfContent.ymax,
    selfContent.ymin,
  ]);

  const handleChangePeriod = (event) => {
    if (event.target.value === "0") return;
    setSelfContent({
      ...selfContent,
      updateTime: event.target.value,
    });
    onChange({ ...selfContent, updateTime: event.target.value });
  };

  // change selected Part
  const handleChangePart = useCallback(
    (node) => {
      setSelfContent({
        ...selfContent,
        partId: node.id,
        label: node.text,
        text: node.text,
      });
      onChange({
        ...selfContent,
        partId: node.id,
        label: node.text,
        text: node.text,
      });
    },
    [onChange, selfContent]
  );

  useEffect(() => {
    updatedefaultDataProps();
    if (nodes.length === 0) {
      loadData();
    }
  }, [loadData, nodes.length, selfContent, updatedefaultDataProps]);

  const changeContentProp = (_sender) => {
    if (!_sender) return;
    const { id, value } = _sender.target;
    selfContent[id] = value;
    setSelfContent({ ...selfContent });
    onChange({ ...selfContent });
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={4}>
          <InputLabel id="dsId">
            Part {selfContent.partId ? selfContent.text : ""}
          </InputLabel>
          <div className={classes.combobox}>
            <DashboarTree
              nodes={nodes}
              initialValue={initialValue}
              handleChangePart={handleChangePart}
            />
          </div>
        </Grid>
        <Grid item xs={8}>
          <InputLabel id="title">Panel Name</InputLabel>
          <TextField
            className={classes.formControl}
            id="title"
            value={panelContent.title ?? ""}
            type="text"
            fullWidth={true}
            onChange={changeContentProp}
          />
          <Grid>
            <Paper className={classes.chart}>
              {type === "Line" && (
                <LineChart data={defaultData} options={options} />
              )}
              {type === "Pie" && (
                <PieChart data={defaultData} options={options} />
              )}
              {type === "Polar" && (
                <PolarChart data={defaultData} options={options} />
              )}
              {type === "BarChart" && (
                <BarChart data={defaultData} options={options} />
              )}
              {type === "Horizontal" && (
                <HorizontalChart data={defaultData} options={options} />
              )}
              {type === "line" && (
                <LineChart data={defaultData} options={options} />
              )}
              {type === "Doughnut" && (
                <DoughnutChart data={defaultData} options={options} />
              )}
              {type === "Gauge" && (
                <Gauge data={defaultData} options={options} />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.form}>
              Edit Data Source:
              <Grid item xs>
                <TextField
                  id="series"
                  label="Series"
                  onChange={changeContentProp}
                  type="text"
                  value={panelContent.series ?? ""}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="alias"
                  label="Alias"
                  onChange={changeContentProp}
                  type="text"
                  value={panelContent.alias ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="ymin"
                  label="y-min"
                  onChange={changeContentProp}
                  value={panelContent.ymin ?? ""}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="ymax"
                  label="y-max"
                  type="number"
                  onChange={changeContentProp}
                  value={panelContent.ymax ?? ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id="scale"
                  label="Y-axis-spacing"
                  value={panelContent.scale ?? 20}
                  type="number"
                  onChange={changeContentProp}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="unit"
                  label="Unit"
                  onChange={changeContentProp}
                  value={panelContent.unit ?? ""}
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          {showSelectedDefaults && (
            <>
              <DatesFilter onDatesChanged={handleChangePeriod} />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
