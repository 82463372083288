import React from "react";

//Material UI
import { Box } from "@material-ui/core";
import PartSearch from "../PartSearch";
import { PartItem } from "../../../../app/models/partitem";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    alignItems: "center",
  },
  label: {
    flexBasis: "40%",
    maxWidth: "40%",
  },
  item: {
    flexBasis: "60%",
    maxWidth: "60%",
  },
}));

interface Props {
  onPartSelect: (part: PartItem) => void;
  parent: string;
  relation: string;
  reverse: boolean;
}

export default function ChildToParent({
  parent,
  relation,
  onPartSelect,
  reverse,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleRelatedPartSelect = (value: PartItem) => {
    if (value && value?.id) {
      onPartSelect(value);
    }
  };

  if (reverse) {
    return (
      <>
        <Box className={classes.container}>
          <Box p={1} className={classes.label}>
            {t("page.addproperty.part.label")}
          </Box>
          <PartSearch width="60%" onItemSelect={handleRelatedPartSelect} />
        </Box>
        <Box
          display="flex"
          p={1}
          justifyContent="center"
          style={{ fontWeight: "bold" }}
        >
          {relation}
        </Box>
        <Box className={classes.container}>
          <Box p={1} className={classes.label}>
            {t("page.addproperty.location.label")}
          </Box>
          <Box p={1} className={classes.item}>
            {parent}
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box className={classes.container}>
          <Box p={1} className={classes.label}>
            {t("page.addproperty.location.label")}
          </Box>
          <Box p={1} className={classes.item}>
            {parent}
          </Box>
        </Box>
        <Box
          display="flex"
          p={1}
          justifyContent="center"
          style={{ fontWeight: "bold" }}
        >
          {relation}
        </Box>
        <Box className={classes.container}>
          <Box p={1} className={classes.label}>
            {t("page.addproperty.part.label")}
          </Box>
          <PartSearch width="60%" onItemSelect={handleRelatedPartSelect} />
        </Box>
      </>
    );
  }
}
