import React from "react";
import { Polar } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import { ChartProps } from "./chartProps";

const useStyles = makeStyles(() => ({
  container: {
    height: "80%",
  },
}));

const PolarChart = (chartProps: ChartProps) => {
  const classes = useStyles();
  <div className={classes.container}></div>;
  return (
    <Polar
      data={chartProps.data}
      options={{
        ...chartProps.options,
        maintainAspectRatio: false,
      }}
    />
  );
};

export default PolarChart;
