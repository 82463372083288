import React from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import { HiStatusOnline } from "react-icons/hi";
import { useTranslation } from "react-i18next";

function getRandomArbitrary(min: any, max: any) {
  return Math.floor(Math.random() * (max - min) + min);
}

export default function Mileage() {
  const { t } = useTranslation();
  const currentMileage = Math.floor(getRandomArbitrary(50, 200))

  return (
    <div style={{ height: 120, minWidth: 220, backgroundColor: "#F2F2F2", border: "1px solid #E0E0E0", borderRadius: 5, cursor:"pointer" }}>
      <Grid container item style={{ marginLeft: 10, marginTop: 10, width: "auto" }} direction="row" justifyContent="flex-start" alignItems="center">

        <HiStatusOnline style={{height: 20, width: 20, color:"green"}}/>
        <Typography variant="h5" style={{ marginLeft: 5 }} >{t("castersetup.maintenance.mileage")}</Typography>
      </Grid>
      <Typography variant="h6" style={{ marginLeft: 10, marginTop: 5 }} >{currentMileage} Km</Typography>
      <Grid container item style={{ marginLeft: 10, width: "auto" }} direction="row" justifyContent="flex-start" alignItems="center">
        <Typography variant="caption">{t("castersetup.maintenance.remaining")}: {450/2-currentMileage} Km</Typography>
      </Grid>
    </div>

  )
}