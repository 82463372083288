import React from "react";

const AssetContext = React.createContext({
  selectRoot: () => {},
  selectViewType: () => {},
  selectPart: () => {},
  clearSelectedPart: () => {},
  onSelectPart: () => {},
  editSettings: () => {},
  refreshPartDetails: () => {},
  refreshTree: () => {},
  rootNode: { id: "", name: "" },
  viewType: -1,
  displaySection: "hide",
  treeData: {
    id: "root",
    name: "",
    children: [],
  },
  relationships: [],
  externalSystems: [],
  selectedPart: { id: "", name: "", remarks: "" },
  partDetails: { properties: [], systems: [] },
});

export default AssetContext;
