/* eslint-disable no-unused-vars */
export enum enPermission {
  ASSET_MANAGEMENT_READ = "asset_management:READ",
  ASSET_MANAGEMENT_WRITE = "asset_management:WRITE",
  DASHBOARDS_READ = "dashboards:READ",
  DASHBOARDS_WRITE = "dashboards:WRITE",
  COCKPITS_READ = "cockpits:READ",
  COCKPITS_WRITE = "cockpits:WRITE",
  CASTER_READ = "caster_setup:READ",
  CASTER_WRITE = "caster_setup:WRITE",
  ONTOLOGY_READ = "ontology:READ",
  ONTOLOGY_WRITE = "ontology:WRITE",
}

export enum enRoles {
  ASSET_MANAGEMENT_ADMIN = "Asset Dictionary Admin",
  ASSET_MANAGEMENT_STANDARD = "Asset Dictionary Standard",
  DASHBOARDS_ADMIN = "Dashboard Admin",
  DASHBOARDS_STANDARD = "Dashboard Standard",
  COCKPITS_ADMIN = "CockPit Admin",
  COCKPITS_STANDARD = "CockPit Standard",
  CASTER_ADMIN = "Caster Admin",
  CASTER_STANDARD = "Caster Standard",
  ONTOLOGY_ADMIN = "Ontology Admin",
  ONTOLOGY_STANDARD = "Ontology Standard",
}
