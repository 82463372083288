import React from "react";
import useApiMessage from "../../common/hooks/useAPIMessage";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const APIMessageNotification = () => {
  const { customMessage, removeMessage } = useApiMessage();

  const handleClose = () => {
    removeMessage();
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {customMessage && (
        <Snackbar
          open={customMessage}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            data-testid="close-alert-notification"
            severity={customMessage ? customMessage.type : ""}
          >
            {customMessage ? customMessage.message : ""}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default APIMessageNotification;
