import { useContext } from "react";
import { APIMessageContext } from "../../providers/APIMessageProvider";

function useApiMessage() {
  const { customMessage, addError, addSuccess, removeMessage, addWarning } =
    useContext(APIMessageContext);
  return { customMessage, addError, removeMessage, addSuccess, addWarning };
}

export default useApiMessage;
