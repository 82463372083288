import React, { Suspense, useState, useEffect } from "react";
import theme from "./themes/DefaultTheme";
import Logo from "./assets/icons/sdig_white.svg";
import LogoBlack from "./assets/icons/sdig_black.svg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import AssetDictionary from "./pages/AssetDictionary";
import Ontology from "./pages/Ontology";
import Cockpits from "./pages/Cockpits";
import Dashboards from "./pages/Dashboards";
import UnAuthorized from "./pages/UnAuthorized";
import Caster from "./pages/CasterSetup";//TODO: Implement index file

//Material-UI
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { Grid } from '@mui/material';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { MenuItem } from "@material-ui/core";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ThemeProvider } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';

import { getToken } from "./services/auth";
import { logout } from "./services/auth";


import APIProgressBarNotification from "./components/APIProgressBar";
import axios from "axios";

import menu from "./components/Menus";
import { useTranslation } from "react-i18next";
import { environment } from "./environments/environments";
import { useLocation } from "react-router";

axios.defaults.baseURL = environment.API_URL;

axios.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const LANG_KEY = "@sms/dt:language";
const languageFromLocalStorage = localStorage.getItem(LANG_KEY) || "en";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    position: "absolute",
    zIndex: theme.zIndex.drawer,
    backgroundColor: "#333333",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  appBarShift: {
    width: `calc(100% - ${theme.drawerWidth}px)`,
    marginLeft: theme.drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: theme.drawerWidth,

      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: theme.drawerWidth,
    backgroundColor: "#FFFFFF"


  },
  drawerHeader: {
    backgroundColor: "transparent",
    display: "flex",
    cursor: "pointer",
    ...theme.mixins.toolbar,

  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  loading: {
    height: "10px",
  },
  languageSelector: {
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF"
    },
    "& fieldset": {
      border: 0,
    },
  },
}));

function DigitalTwin() {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const [lang, setLang] = useState(languageFromLocalStorage);
  useEffect(() => {
    localStorage.setItem(LANG_KEY, lang);
  }, [lang]);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState(menu);
  const [title, setTitle] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListClick = (sender: string) => {
    setTitle(sender);
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  useEffect(() => {
    setMenus(menu);
  }, []);

  const handleLanguageChange = (e: SelectChangeEvent) => {
    setLang(e.target.value);
    i18n.changeLanguage(e.target.value as string);
  };

  useEffect(() => {
    switch ((location.pathname.split("/app"))[1].split("/")[1]) {
      case undefined:
        setTitle("app.menu.assetdictionary")
        break;
      case "caster":
        setTitle("app.menu.castersetup")
        break;
      case "cockpits":
        setTitle("app.menu.cockpits")
        break;
      case "dashboards":
        setTitle("app.menu.dashboards")
        break;
      case "ontology":
        setTitle("app.menu.ontology")
        break;
    }
  }, [location]);


  //TODO: Split in components (refactor)
  return (
    <div style={{ backgroundColor: theme.pageBackground, height: "100vh" }}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBar
            position="static"
            className={classes.appBar}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <img src={Logo} width="20" alt={"logo"} />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t("title")} - {t(title)}
              </Typography>

              <Select
                IconComponent={KeyboardArrowDownIcon}
                className={classes.languageSelector}
                labelId="language"
                id="language"
                value={lang}
                onChange={handleLanguageChange}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ color: "white" }}


              >
                <MenuItem value={"en"}>EN</MenuItem>
                <MenuItem value={"ru"}>RU</MenuItem>
              </Select>
              <p></p>
              <Button onClick={handleLogout} color="inherit">
                <LogoutIcon />
              </Button>
            </Toolbar>
          </AppBar>

          <div className={classes.appBarSpacer} />
          <Drawer
            className={classes.drawer}
            onBackdropClick={() => {
              handleDrawerClose();
            }}
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader} >
              <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="center" onClick={handleDrawerClose} >

                {theme.direction === "ltr" ? (
                  <><MenuIcon style={{ marginLeft: 24, color: "#666666" }} />
                    <img src={LogoBlack} width="20" alt={"logo"} style={{ marginLeft: 28 }} />
                    <Typography variant="h6" style={{ color: "#666666", marginLeft: 28, marginRight: 25, fontWeight: "bold" }}>
                      {t("title")}
                    </Typography></>
                ) : (
                  <ChevronRightIcon />
                )}

              </Grid>
            </div>
            <Divider />
            <List style={{ marginLeft: 8 }}>
              {menus.map((menu) => {
                return (
                  <div key={menu.id}>
                    {menu.visible && (
                      <ListItem
                        onClick={() => handleListClick(menu.name)}
                        button
                        key={menu.path}
                        component={Link}
                        to={menu.path}
                      >
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={t(menu.name)} key={menu.name} />
                      </ListItem>
                    )}
                  </div>
                );
              })}
            </List>
          </Drawer>


          <div key={"apiprogressbar"} className={classes.loading}>
            <APIProgressBarNotification />
          </div>

          <Switch>

            <Route path="/app/cockpits">
              <Cockpits />
            </Route>
            <Route path="/app/dashboards">
              <Dashboards />
            </Route>
            <Route path="/app/caster">
              <Suspense fallback="Loading <CasterSetup /> from <DigitalTwin /> ...">
                <Caster />
              </Suspense>
            </Route>
            <Route path="/app/ontology">
              <Ontology />
            </Route>
            <Route path="/app/unauthorized">
              <UnAuthorized />
            </Route>
            <Route path="/app">
              <AssetDictionary />
            </Route>

          </Switch>

        </ThemeProvider>
      </Router>
    </div>
  );
}

export default DigitalTwin;
