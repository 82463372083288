import "date-fns";
import React, { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { endOfDay, isAfter, isBefore, isEqual } from "date-fns";
import { DEFAULT_START_DATE, DEFAULT_END_DATE } from "./defaultConfigs";
import { DateFields } from "./dates.interface";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    maxWidth: 485,
  },
  title: {
    color: "#666666",
  },
  error: {
    color: "red",
    width: "100%",
    textAlign: "center",
  },
  datePicker: {
    width: 240,
    backgroundColor: "#f2f2f2",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
        borderBottom: "2px solid #bfbfbf",
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2b7cd8",
      },
    },
  },
});

interface Props {
  onDatesChanged: (dates: DateFields) => void;
  enabled: boolean;
  start?: Date;
  end?: Date;
}

export default function DatePickers({
  onDatesChanged,
  enabled,
  start,
  end,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(DEFAULT_START_DATE);
  const [endDate, setEndDate] = React.useState(DEFAULT_END_DATE);

  const [isValid, setIsValid] = React.useState(true);

  useEffect(() => {
    setStartDate(start ?? DEFAULT_START_DATE);
  }, [start]);

  useEffect(() => {
    setEndDate(end ?? DEFAULT_END_DATE);
  }, [end]);

  const handleStartDateChanged = (date: MaterialUiPickersDate) => {
    const newDate = new Date(date ?? 0);
    if (!isEqual(newDate, startDate)) {
      setStartDate(newDate);
      if (isAfter(newDate, endDate)) {
        setIsValid(false);
        return;
      }
      onDatesChanged({ startDate: newDate, endDate });
      setIsValid(true);
    }
  };

  const handleEndDateChanged = (date: MaterialUiPickersDate) => {
    const newDate = new Date(date ?? 0);
    if (!isEqual(newDate, endDate)) {
      setEndDate(newDate);
      if (isBefore(newDate, startDate)) {
        setIsValid(false);
        return;
      }
      onDatesChanged({ startDate, endDate: newDate });
      setIsValid(true);
    }
  };

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
        >
          <Grid item container direction="column" xs={6}>
            <Typography className={classes.title} variant="caption">
              {t("datePicker.startDate")}
            </Typography>
            <KeyboardDateTimePicker
              className={classes.datePicker}
              disabled={!enabled}
              size="small"
              ampm={false}
              format="dd/MM/yyyy HH:mm:ss"
              id="start-date"
              value={startDate}
              onChange={handleStartDateChanged}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography className={classes.title} variant="caption">
              {t("datePicker.endDate")}
            </Typography>
            <KeyboardDateTimePicker
              className={classes.datePicker}
              disabled={!enabled}
              id="end-date"
              size="small"
              ampm={false}
              format="dd/MM/yyyy HH:mm:ss"
              value={endDate}
              maxDate={endOfDay(new Date())}
              onChange={handleEndDateChanged}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <div className={classes.error}>
        {!isValid && <span>Invalid dates range</span>}
      </div>
    </div>
  );
}
