import React, { useCallback, useState } from "react";
import { Relationship } from "../../../../app/models/relationship";
import SelectSideLabel from "../../../../components/SelectSideLabel";
import ChildToParent from "./ChildToParent";
import { PartItem } from "../../../../app/models/partitem";
import SystemSection from "./SystemSection";
import Attribute from "./Attribute";
import { useTranslation } from "react-i18next";

interface Props {
  items: Relationship[];
  parent: string;
  onRelatedPart: (part: PartItem) => void;
  onRelationSelect: (id: number) => void;
  onNameChange: (value: string) => void;
  onValueChange: (value: string) => void;
  onSystemId: (value: string) => void;
  onSystemDesc: (value: string) => void;
  onSystemSelect: (value: string) => void;
}

export default function RelationshipSection({
  items,
  parent,
  onRelatedPart,
  onRelationSelect,
  onNameChange,
  onValueChange,
  onSystemId,
  onSystemDesc,
  onSystemSelect,
}: Props) {
  const { t } = useTranslation();
  const [selectedRel, setSelectedRel] = useState(-1);

  const handleSelect = useCallback(
    (id: number) => {
      if (id) {
        setSelectedRel(id);
        onRelationSelect(id);
      }
    },
    [onRelationSelect]
  );

  function renderBody() {

    const selected = items.find((x) => x.id === selectedRel);
    switch (selected?.rule) {
      case "system":
        return (
          <SystemSection
            onDescChanged={onSystemDesc}
            onIdChanged={onSystemId}
            onSystemChanged={onSystemSelect}
          />
        );
      case "install_reverse":
        return (
          <ChildToParent
            reverse
            parent={parent}
            relation={selected.description}
            onPartSelect={onRelatedPart}
          />
        );
      case "install":
        return (
          <ChildToParent
            reverse={false}
            parent={parent}
            relation={selected.description}
            onPartSelect={onRelatedPart}
          />
        );
      case "attribute":
        return (
          <Attribute
            onNameChange={onNameChange}
            onValueChange={onValueChange}
          />
        );
      default:
        return null;
    }
  }

  return (
    <>
      <SelectSideLabel
        onSelectedItem={handleSelect}
        selectedValue={selectedRel}
        items={items}
        label={t("page.addproperty.relationship.label")}
      />
      {selectedRel !== -1 && renderBody()}
    </>
  );
}
