import React from "react";
import { installationTreeResource } from "../../../../../services/suspenders";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
//Material-UI
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.textSecondary,
    width: "100%",
  },
  link: {
    display: "inline",
    cursor: "pointer",
    fontSize: 17,
    color: theme.link,
    border: `1px solid ${theme.mediumContrastBorder}`,
    borderRadius: 10,
    marginRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
  valueDiv: {
    width: "100%",
    marginBottom: 10,
  },
  value: {
    fontSize: 17,
    display: "inline",
  },
  linkValueDiv: {
    width: "100%",
    marginTop: 5,
    marginBottom: 15,
  },
  properties: {
    width: "100%",
  },
}));

interface Props {
  selectedPart: any;
  positionFromPart: any;
  setMainView: any;
  setDetailsView: any;
}

export default function PartDetails({
  setDetailsView,
  setMainView,
  selectedPart,
  positionFromPart,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { installableParts } = installationTreeResource.read();
  const part =
    typeof selectedPart === "object"
      ? selectedPart.part
        ? installableParts[selectedPart.part]
        : undefined
      : undefined;
  const currentPosition = part?.currentlyInstalledAt;
  const positionName = currentPosition?.name;

  if (!part)
    return <Typography>{t("castersetup.details.noDetailsForPart")}</Typography>;

  return (
    <div>
      <Typography className={classes.title} variant="caption">
        {t("grid.label.name")}
      </Typography>
      <div className={classes.linkValueDiv}>
        <p
          className={classes.link}
          onClick={() => {
            history.push(`../caster/${part.id}`);
            setMainView("1");
            setDetailsView("1");
            positionFromPart("");
          }}
        >
          {`${part.name}`}
        </p>
      </div>

      <Typography className={classes.title} variant="caption">
        {t("grid.label.id")}
      </Typography>
      <div className={classes.valueDiv}>
        <p className={classes.value}>{part.id}</p>
      </div>

      <Typography className={classes.title} variant="caption">
        {t("castersetup.details.consumes")}
      </Typography>
      <div className={classes.valueDiv}>
        <p className={classes.value}>Energy</p>
      </div>

      <Typography className={classes.title} variant="caption">
        {t("castersetup.details.isPartOf")}
      </Typography>
      <div className={classes.valueDiv}>
        <p className={classes.value}>drive chain</p>
      </div>

      <Typography className={classes.title} variant="caption">
        {t("castersetup.details.isInstalledAt")}
      </Typography>
      {currentPosition ? (
        <div className={classes.linkValueDiv}>
          <p
            className={classes.link}
            onClick={() => {
              history.push(`../caster/${currentPosition.belongsTo.id}`);
              positionFromPart(
                currentPosition.id,
                currentPosition.belongsTo.id
              );
              setMainView("3");
            }}
          >
            {`${positionName}`}
          </p>
        </div>
      ) : (
        <div className={classes.valueDiv}>
          <p className={classes.value}>
            {t("castersetup.details.notInstalled")}
          </p>
        </div>
      )}
      <Grid
        container
        style={{ width: "100%", marginBottom: 10 }}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid xs={2} item>
          <Typography className={classes.title} variant="caption">
            {t("castersetup.details.installationDate")}
          </Typography>
          <div>
            <p className={classes.value}>2021-06-01</p>
          </div>
        </Grid>
        <Grid xs={2} item>
          <Typography className={classes.title} variant="caption">
            {t("castersetup.details.castTons")}
          </Typography>
          <div>
            <p className={classes.value}>200 t</p>
          </div>
        </Grid>
      </Grid>

      <Typography className={classes.title} variant="caption">
        {t("castersetup.details.canBeInstalledAt")}
      </Typography>
      <Grid container style={{ marginTop: -15 }}>
        {part.installableAt.map((position) => (
          <p
            key={position.id}
            className={classes.link}
            onClick={() => {
              if (currentPosition) {
                history.push(`../caster/${position.belongsTo.id}`);
                positionFromPart(position.id, position.belongsTo.id);
                setMainView("3");
              }
            }}
          >
            {position.name}
          </p>
        ))}
      </Grid>
    </div>
  );
}
