import { useContext } from "react";
import { APIProgressBarContext } from "../../providers/APIProgressBar";

function useApiProgressBar() {
  const { isLoading, showProgressBar, hideProgressBar } = useContext(
    APIProgressBarContext
  );
  return { isLoading, showProgressBar, hideProgressBar };
}

export default useApiProgressBar;
