import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Agent from "../../services/presentationService";
import DigitalECService from "../../services/digitalEcService";
import GeniusCMService from "../../services/geniusCmService";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";

import CockPitPannel from "./components/panels";
import { DatePicker } from "../../components/DateUtils";
import { Runner } from "../../components/Presentation";
import useProgressBar from "../../common/hooks/useProgressBar";

import { Pannel, Presentation } from "../../app/models/presentation";
import { DateFields } from "../../components/DateUtils/dates.interface";
import { CockPitTreeNode } from "../../app/models/cockPitTreeNode";
import { DECJobs } from "../../app/models/decJobs";
import { ParamTypes } from "../../app/models/commons";
import {
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from "../../components/DateUtils/defaultConfigs";

// this is used to select cockpit items.
const useStyles = makeStyles({
  container: {
    color: "#BBB",
    borderWidth: "1.5px",
    borderStyle: "solid",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  title: {
    paddingLeft: "15px",
    fontStretch: "bold",
  },
});

export default function RunCockpit() {
  const classes = useStyles();
  let { id } = useParams<ParamTypes>();

  const [chartData, setChartData] = useState();

  const startDates: DateFields = {
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  };
  const [dates, setDates] = useState(startDates);
  const { showProgressBar, hideProgressBar } = useProgressBar();

  const [node, setNode] = useState<CockPitTreeNode>();
  const [jobs, setJobs] = useState<DECJobs[]>([]);
  const [alarm, setAlarm] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cockPit, setCockPit] = useState<Presentation>();
  const [daily, setDaily] = useState(0);
  const [decId, setDecId] = useState("");
  const [canSelectDates, setCanSelectDates] = useState(false);

  const loadData = useCallback(
    async (node?: CockPitTreeNode) => {
      if (!node || !node.id) return;
      showProgressBar();
      const alarm = await GeniusCMService.getAlarmStatus(node.id);
      setAlarm(alarm);

      let jobs = await DigitalECService.DigitalEquipamentCheck.jobs(
        decId,
        dates
      );
      setJobs(jobs);

      // get data from charts
      let data = await GeniusCMService.getData(
        dates.startDate,
        dates.endDate,
        node.id,
        daily
      );
      setChartData(data);
      hideProgressBar();
    },
    [showProgressBar, decId, dates, daily, hideProgressBar]
  );

  useEffect(() => {
    (async () => {
      if (!isLoaded && !cockPit) {
        const result = await Agent.Presentations.details(id);
        if (!result) return;
        setDecId(result?.partId || "");
        setCockPit(result);
        setIsLoaded(true);
        setCanSelectDates(
          !(result.pannels.length === 1 && result.pannels[0].id === "edoc")
        );
      }

      await loadData(node);
    })();
  }, [node, dates, isLoaded, cockPit, loadData, id]);

  const handleDatesChanged = async (dates: DateFields) => {
    let jobs = await DigitalECService.DigitalEquipamentCheck.jobs(decId, dates);
    setJobs(jobs);
    setDates(dates);
  };

  const onSelectedNode = async (node: CockPitTreeNode) => {
    setNode(node);
  };

  const renderContent = (pannel: Pannel) => {
    return (
      <CockPitPannel
        key={pannel.id}
        cockPitItem={pannel}
        jobs={jobs}
        alarm={alarm}
        nodeName={node ? node.name : ""}
        selectedNode={onSelectedNode}
        chartData={chartData ? chartData : undefined}
      />
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaily(Number(event.target.checked));
  };

  return (
    <>
      {cockPit && cockPit.pannels && (
        <>
          <div className={classes.header}>Cockpit: {cockPit.name}</div>
          <Grid container>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={8}>
                <DatePicker
                  enabled={canSelectDates}
                  onDatesChanged={handleDatesChanged}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Daily"
                      onChange={handleChange}
                      inputProps={{ "aria-label": "daily" }}
                      disabled={!canSelectDates}
                    />
                  }
                  label="Daily Average"
                />
              </Grid>
            </Grid>
          </Grid>

          <Runner
            presentation={cockPit}
            renderChildren={renderContent}
            isDraggable={false}
            isResizable={false}
          />
        </>
      )}
    </>
  );
}
