import React, { useEffect, useState, useCallback } from "react";

import { useParams } from "react-router-dom";
import Agent from "../../services/presentationService";
import useProgressBar from "../../common/hooks/useProgressBar";
import PrepareDashboard from "./prepare-dashboard";

interface ParamTypes {
  id: string;
}

export default function EditDashboard() {
  let { id } = useParams<ParamTypes>();

  const [dashboard, setDashboard] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const { showProgressBar, hideProgressBar } = useProgressBar();

  const loadData = useCallback(async () => {
    showProgressBar();
    const result = await Agent.Presentations.details(id);

    const pannelsContent = result.pannels.map((pannel) => pannel.pannelContent);

    setDashboard({
      ...result,
      pannelsContent,
    });
    setIsLoaded(true);
    hideProgressBar();
  }, [hideProgressBar, id, showProgressBar]);

  useEffect(() => {
    loadData();
  }, [id, loadData]);

  return <>{isLoaded && <PrepareDashboard current={dashboard} />}</>;
}
