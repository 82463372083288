import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Agent from "../../services/presentationService";
import useProgressBar from "../../common/hooks/useProgressBar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import DashboardPanels from "./components/dashboardPanels";
import { DatesFilter } from "../../components/DateUtils";
import { Runner } from "../../components/Presentation";
import {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
} from "../../components/DateUtils/defaultConfigs";
import { Pannel, Presentation } from "../../app/models/presentation";
import { ParamTypes } from "../../app/models/commons";
import { DateFields } from "../../components/DateUtils/dates.interface";

// this is used to select cockpit items.
const useStyles = makeStyles({
  header: {
    width: "100%",
    textAlign: "center",
  },
});

export default function RunDashboard() {
  const classes = useStyles();
  let { id } = useParams<ParamTypes>();

  const startDates: DateFields = {
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  };

  const [dates, setDates] = useState(startDates);

  const { showProgressBar, hideProgressBar } = useProgressBar();
  const [dashboard, setDashboard] = useState<Presentation>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [daily, setDaily] = useState(0);

  useEffect(() => {
    (async () => {
      if (!isLoaded && !dashboard) {
        showProgressBar();
        const result = await Agent.Presentations.details(id);
        if (result) {
          setDashboard(result);
        }
        setIsLoaded(true);
        hideProgressBar();
      }
    })();
  }, [dashboard, dates, hideProgressBar, id, isLoaded, showProgressBar]);

  const handleDatesChanged = async (dates: DateFields) => {
    showProgressBar();
    setDates(dates);
    hideProgressBar();
  };

  const renderContent = (presentationItem: Pannel) => {
    return (
      <DashboardPanels
        key={presentationItem.id}
        dashboardItem={presentationItem}
        presentationMode={true}
        startDate={dates.startDate}
        endDate={dates.endDate}
        daily={daily}
      />
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaily(Number(event.target.checked));
  };

  return (
    <>
      {dashboard && dashboard.pannels && (
        <>
          <div className={classes.header}>Dashboard: {dashboard.name}</div>
          <Grid container>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={8}>
                <DatesFilter
                  updateTime={"60"}
                  onDatesChanged={handleDatesChanged}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Daily"
                      onChange={handleChange}
                      inputProps={{ "aria-label": "daily" }}
                    />
                  }
                  label="Daily Average"
                />
              </Grid>
            </Grid>
          </Grid>

          <Runner
            presentation={dashboard}
            renderChildren={renderContent}
            isDraggable={false}
            isResizable={false}
          />
        </>
      )}
    </>
  );
}
