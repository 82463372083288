import React from "react";
import PolarChart from "./polar";
import LineChart from "./line";
import BarChart from "./bar";
import HorizontalChart from "./horizontal";
import DoughnutChart from "./doughnut";
import PieChart from "./pie";
import Gauge from "./gauge";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import data from "../../../../components/Presentation/samplesData.json";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chart: {
    height: 150,
    width: 225,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  onSelect: (type: string) => {};
}

export const AvaiableCharts = ({ onSelect }: Props) => {
  const classes = useStyles();
  const options = {
    legend: {
      display: false,
    },
  };
  return (
    <Grid>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs key={1}>
              <Paper className={classes.chart}>
                <LineChart data={data} options={options} height={150} />
                Line
              </Paper>
              <Button onClick={() => onSelect("Line")} variant="contained">
                add
              </Button>
            </Grid>
            <Grid item xs>
              <Paper className={classes.chart}>
                <PolarChart data={data} options={options} height={40} />
                Polar
              </Paper>
              <Button onClick={() => onSelect("Polar")} variant="contained">
                add
              </Button>
            </Grid>
            <Grid item xs>
              <Paper className={classes.chart}>
                <DoughnutChart data={data} options={options} />
                Doughnut
              </Paper>
              <Button onClick={() => onSelect("Doughnut")} variant="contained">
                add
              </Button>
            </Grid>
            <Grid item xs>
              <Paper className={classes.chart}>
                <BarChart data={data} options={options} />
                Bar
              </Paper>
              <Button onClick={() => onSelect("BarChart")} variant="contained">
                add
              </Button>
            </Grid>
            <Grid item xs>
              <Paper className={classes.chart}>
                <HorizontalChart data={data} options={options} />
                Horizontal Column
              </Paper>
              <div>
                <Button
                  onClick={() => onSelect("Horizontal")}
                  variant="contained"
                >
                  add
                </Button>
              </div>
            </Grid>
            <Grid item xs>
              <Paper className={classes.chart}>
                <PieChart data={data} options={options} />
                Pie
              </Paper>
              <div>
                <Button onClick={() => onSelect("Pie")} variant="contained">
                  add
                </Button>
              </div>
            </Grid>
            <Grid item xs>
              <Paper className={classes.chart}>
                <Gauge />
                Gauge
              </Paper>
              <div>
                <Button onClick={() => onSelect("Gauge")} variant="contained">
                  add
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
