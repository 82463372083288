export interface Suspender<T> {
  read(): T;
}

export function wrapPromise<T>(promise: Promise<T>): Suspender<T> {
  // from https://codesandbox.io/s/frosty-hermann-bztrp?file=/src/fakeApi.js:395-853
  let status: "pending" | "error" | "success" = "pending";
  let result: T;

  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
      return result;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read(): T {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      } else {
        // typescripts type inference is a little stupid ...
        return result;
      }
    },
  };
}

export async function awaitSuspender<T>(suspender: Suspender<T>) {
  try {
    return suspender.read();
  } catch (e) {
    if (typeof (e as any)?.then === "function") {
      // e instanceof Promise is not reliable
      return await e;
    } else {
      throw e;
    }
  }
}
