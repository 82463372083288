import { TOKEN_KEY } from "./auth";
import { JwtToken } from "../app/models/jwtToken";
import jwtDecode from "jwt-decode";

class PermissionsService {
  _tokenValid(decoded: JwtToken, requireSecondsLeft=0) {
    const now = Date.now() / 1000;
    if(decoded.nbf && decoded.nbf < now) 
      return false;
    if(decoded.exp + requireSecondsLeft < now)
      return false;
    else
      return true;
  }

  /** Check token is not expired */
  tokenValid(token: string, requireSecondsLeft=0) {
    const decoded: JwtToken = jwtDecode(token);
    return this._tokenValid(decoded);
  }
  //decode token, get permissions and verify if is authorized
  // if not authorized to this, must redirec to unauthrized and clean cache
  checkApplicationPermission(token: any): boolean {
    const authToken: JwtToken = jwtDecode(token);
    return (
      this._tokenValid(authToken) &&
      (authToken.is_admin || authToken.security.digitaltwin !== undefined)
    );
  }

  hasPermission(permission: string): boolean {
    if (this.isAdmin()) return true;
    const token = localStorage.getItem(TOKEN_KEY) || "";
    if (token === "") return false;
    const authToken: JwtToken = jwtDecode(token);

    // checks if token is alive and the user has Roles set
    if (!this._tokenValid(authToken) || !authToken.security.digitaltwin) 
      return false;

    return authToken.security.digitaltwin
      ? authToken.security.digitaltwin.permissions.includes(permission)
      : false;
  }

  isUserInRole(role: string) {
    if (this.isAdmin()) return true;
    const token = localStorage.getItem(TOKEN_KEY) || "";
    if (token === "") return false;
    const authToken: JwtToken = jwtDecode(token);
    return this._tokenValid(authToken) && authToken.security.digitaltwin
      ? authToken.security.digitaltwin.roles.includes(role)
      : false;
  }

  isAdmin() {
    const token = localStorage.getItem(TOKEN_KEY) || "";
    if (token === "") return false;
    const authToken: JwtToken = jwtDecode(token);
    if(!this._tokenValid(authToken)) return false;
    if (authToken.is_admin) return true;
    return authToken.security.digitaltwin
      ? authToken.security.digitaltwin.is_admin
      : false;
  }
}

const permission = new PermissionsService();

export default permission;
