import React from "react";
import Grid from "@mui/material/Grid";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid grey",
    borderRadius: 5,
    backgroundColor: "white",
    maxWidth: 700,
  },
  item: {
    padding: 5,
    width: "100%",
  },
  headLine: {
    fontSize: "19px",

  },
  headLineValue: {
    fontSize: "19px",
    wordWrap: "break-word"
  },
  infoHeadLine: {
    fontSize: "17px",

  },
  infoHeadLineValue: {
    fontSize: "17px",
    wordWrap: "break-word"

  },
  textarea: {
    fontFamily: "Roboto",
    backgroundColor: "transparent",
    width: "100%",
    height: 350,
    resize: "none",
    fontSize: 17,
    marginTop: -10,
  },
  button: theme.darkButton
}));

function convert_date(date: any) {
  var new_date =
    date.getUTCDate() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getUTCFullYear() +
    ", " +
    date.getUTCHours() +
    ":" +
    date.getUTCMinutes() +
    ":" +
    date.getUTCSeconds();
  return new_date;
}
export default function JobReport({ data, onClick }: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  var job_title = data["jobTitle"];
  var equipment = data["content"][0]["value"][0]["objectDisplayValue"];
  var reporter = data["userFullName"];
  var started = convert_date(new Date(Date.parse(data["started"])));
  var updated = convert_date(new Date(Date.parse(data["updated"])));
  var finished = convert_date(new Date(Date.parse(data["finished"])));
  var received = convert_date(new Date(Date.parse(data["received"])));
  var job_description =
    data["content"][1]["label"] + ": " + data["content"][1]["value"];

  return (
    <>
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <h1>{t("castersetup.jobReport.title")}</h1>
        <Grid
          className={classes.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p className={classes.headLine}>
              {t("castersetup.jobReport.partName")}:
            </p>
          </Grid>
          <Grid item xs={9}>
            <p
              className={classes.headLineValue}
            >{`${job_title}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -40 }}
          className={classes.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p className={classes.infoHeadLine}>
              {t("castersetup.jobReport.equipment")}:
            </p>
          </Grid>
          <Grid item xs={9}>
            <p
              className={classes.infoHeadLineValue}
            >{`${equipment}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          className={classes.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p className={classes.infoHeadLine}>
              {t("castersetup.jobReport.reporter")}:
            </p>
          </Grid>
          <Grid item xs={9}>
            <p
              className={classes.infoHeadLineValue}
            >{`${reporter}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          className={classes.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p className={classes.infoHeadLine}>
              {t("castersetup.jobReport.started")}:
            </p>
          </Grid>
          <Grid item xs={9}>
            <p
              className={classes.infoHeadLineValue}
            >{`${started}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          className={classes.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p className={classes.infoHeadLine}>
              {t("castersetup.jobReport.updated")}:
            </p>
          </Grid>
          <Grid item xs={9}>
            <p
              className={classes.infoHeadLineValue}
            >{`${updated}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          className={classes.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p className={classes.infoHeadLine}>
              {t("castersetup.jobReport.finished")}:
            </p>
          </Grid>
          <Grid item xs={9}>
            <p
              className={classes.infoHeadLineValue}
            >{`${finished}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          className={classes.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p className={classes.infoHeadLine}>
              {t("castersetup.jobReport.received")}:
            </p>
          </Grid>
          <Grid item xs={9}>
            <p
              className={classes.infoHeadLineValue}
            >{`${received}`}</p>
          </Grid>
        </Grid>
        <Grid
          className={classes.item}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0.1}
        >
          <p className={classes.infoHeadLine}>
            {t("castersetup.jobReport.jobDescription")}:
          </p>
          <textarea
            className={classes.textarea}
            readOnly={true}
            spellCheck="false"
            defaultValue={`${job_description}`}
          />
        </Grid>
        <Grid
          style={{ padding: 5, width: "100%"}}
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Button variant="contained" className={classes.button} onClick={onClick}>
            {t("button.close")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
