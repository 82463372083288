import React, { useState, useCallback } from "react";

export const APIProgressBarContext = React.createContext({
  isLoading: false,
  showProgressBar: () => {},
  hideProgressBar: () => {},
});

export default function APIProgressBarProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const contextValue = {
    isLoading,
    showProgressBar: useCallback(() => setIsLoading(true), []),
    hideProgressBar: useCallback(() => setIsLoading(false), []),
  };

  return (
    <APIProgressBarContext.Provider value={contextValue}>
      {children}
    </APIProgressBarContext.Provider>
  );
}
