import { DECJobs, DECReportResult, DecResult } from "./../app/models/decJobs";
import axios, { AxiosResponse } from "axios";
import { format } from "date-fns";
import { DateFields } from "../components/DateUtils/dates.interface";
import { Agent as API } from "../app/api/agent";
import { environment } from "../environments/environments";

const baseUrl = "/digitalequipmentcheck";

axios.defaults.baseURL = environment.API_URL;
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const DigitalEquipamentCheck = {
  jobs: async (decId: string, dates?: DateFields): Promise<DECJobs[]> => {
    //prevent error on fetch
    if (decId === "") return [];
    const result = await API.PlantModel.get(decId).catch((error) => {
      console.log(error);
      return { modelId: "", objectId: "" };
    });
    const start =
      dates && dates.startDate ? dates.startDate : new Date(2018, 1, 1);
    let end = dates && dates.endDate ? dates.endDate : new Date();

    const requestURl = `${baseUrl}/jobs/calendar/page?start=${format(
      start,
      "yyyy-MM-dd"
    )}&end=${format(end, "yyyy-MM-dd")}&filter=all&rModelId=${result.modelId
      }&rObjectId=${result.objectId
      }&jobStatus&search&showArchived=false&showJobs=true&showTasks=false&sort&overdue=false&page=0&size=100`;

    let resultQueryByModelId = await request.get<DecResult>(requestURl);
    return resultQueryByModelId.content;
  },
  report: async (jobId: number) =>
    await request.get<DECReportResult>(
      `${baseUrl}/jobs/${jobId}/report?lang=en`
    ),
};

const Agent = {
  DigitalEquipamentCheck,
};

export default Agent;
