import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { ViewRelationship } from "../../../../app/models/plantview";
import { Agent } from "../../../../app/api/agent";

import { enPermission } from "../../../../app/models/permissions";
import PermissionsService from "../../../../services/permissionsService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: "100%",
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    filter: {
      paddingRight: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

interface Props {
  items: ViewRelationship[];
  onDeleteItem: (id: number) => void;
}

export default function ItemList({ items, onDeleteItem }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const onRemoveItem = async (id: number) => {
    await Agent.Plantviews.deleteRelationship(id).then(() => {
      onDeleteItem(id);
    });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {t("page.plantview.list.label")}
      </Typography>
      <div className={classes.demo}>
        <List dense={true}>
          {items.map((item) => (
            <ListItem key={item.id}>
              <ListItemText primary={item.name} />
              <ListItemText className={classes.filter} primary={item.filter} />
              <ListItemSecondaryAction>
                {PermissionsService.hasPermission(
                  enPermission.ASSET_MANAGEMENT_WRITE
                ) && (
                    <IconButton
                      key={item.id}
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        onRemoveItem(item.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}
