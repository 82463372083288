import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { useParams } from "react-router-dom";
import Agent from "../../services/presentationService";
import { useHistory } from "react-router-dom";
import { PresentationContext } from "../../context/presentation-context";

import SelectPanels from "./components/select-panels";

import useProgressBar from "../../common/hooks/useProgressBar";

const useStyles = makeStyles({
  container: {
    color: "#BBB",
    borderWidth: "1.5px",
    borderStyle: "solid",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  title: {
    paddingLeft: "15px",
    fontStretch: "bold",
  },
});

export default function EditCockPit() {
  let { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [state, dispatch] = useContext(PresentationContext);
  const { showProgressBar, hideProgressBar } = useProgressBar();

  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const [cockPit, setCockPit] = useState(state.currentPresentation);

  const [isValid, setIsvalid] = useState(false);

  const getPannels = () => {
    const newPannels = [];

    selectedItems.forEach((item) => {
      let pannelLocation = cockPit.pannels.find(
        (currentPannel) => currentPannel.id === item.id
      );
      if (pannelLocation) {
        newPannels.push(item);
      } else {
        newPannels.push({
          id: item.id,
          name: item.name,
          visualization_type: item.visualization_type,
          pannelLocation: pannelLocation
            ? pannelLocation
            : {
                id: item.id,
                i: item.id,
                x: 0,
                y: 0,
                w: 3,
                h: 4,
              },
        });
      }
    });

    return newPannels;
  };

  const goToPrepare = () => {
    const newPannels = getPannels();

    dispatch({
      type: "SET_CURRENT_PRESENTATION",
      payload: { ...cockPit, Pannels: newPannels },
    });
    history.push("/app/cockpits/prepare");
  };

  const goToList = () => {
    history.push("/app/cockpits");
  };

  useEffect(() => {
    (async () => {
      showProgressBar();
      const result = await Agent.Presentations.details(id);
      setCockPit(result);

      setCheckedItems(result.pannels.map((panel) => panel.id));
      setSelectedItems(
        result.pannels.map((panel) => ({
          id: panel.id,
          name: panel.name,
          visualization_type: panel.visualization_type,
          pannelLocation: panel.pannelLocation,
        }))
      );
      setIsLoaded(true);
      setIsvalid(true);
      hideProgressBar();
    })();
  }, [hideProgressBar, id, showProgressBar]);

  const handlerItemChecked = (item, checked) => {
    // return
    const find = selectedItems.find((selected) => selected.i === item.id);
    // prevent reinsert item
    if (checked && !find) {
      selectedItems.push({
        ...item,
        pannelLocation: item.pannelLocation,
      });

      setSelectedItems(selectedItems);
      setIsvalid(selectedItems.length > 0);
    } else {
      let filterdList = selectedItems.filter(
        (removed) => removed.id !== item.id
      );
      setSelectedItems(filterdList);
      setIsvalid(filterdList.length > 0);
    }
  };

  return (
    <>
      {isLoaded && (
        <>
          <div className={classes.header}>Manteinance Cockpit</div>
          <div className={classes.title}>Cockpit Name: {cockPit.name}</div>
          <SelectPanels
            isValid={isValid}
            handlerItemChecked={handlerItemChecked}
            showItems={isLoaded}
            checkedItems={checkedItems}
          />
        </>
      )}

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" m={2} p={1}>
          <Box p={1}>
            <Button color={"primary"} variant="contained" onClick={goToList}>
              Back
            </Button>
            {isValid && (
              <Button color="primary" variant="contained" onClick={goToPrepare}>
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
}
