import React, { useState } from "react";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const updatesAvailable = [
  {
    value: "0.1667",
    label: "10 sec",
  },
  {
    value: "0.5",
    label: "30 sec",
  },
  {
    value: "1",
    label: "1 min",
  },
  {
    value: "5",
    label: "5 min",
  },
  {
    value: "15",
    label: "15 min",
  },
  {
    value: "30",
    label: "30 min",
  },
  {
    value: "60",
    label: "60 min",
  },
];

interface Props {
  updatTimeChanged: (updateAt: number) => void;
}

const RefreshTime = ({ updatTimeChanged }: Props) => {
  const classes = useStyles();
  const [updateAt, setUpdateAt] = useState(1);

  const handleChangePeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateAt(Number(event.target.value));
    updatTimeChanged(Number(event.target.value) * 60 * 1000);
  };
  return (
    <div className={classes.root}>
      <TextField
        id="updateAt"
        select
        label="Refresh At"
        value={updateAt}
        onChange={handleChangePeriod}
        SelectProps={{
          native: true,
        }}
      >
        {updatesAvailable.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </div>
  );
};

export default RefreshTime;
