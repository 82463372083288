import { makeAutoObservable } from "mobx";
import { eDocLoader } from "../components/eDocLoader";

export default class CasterSetupStore {
  eDocuments = [] as any;
  rightGridHeight: number | undefined = undefined;
  alarm = { open: false, id: "", name: "", position: "" };

  constructor() {
    makeAutoObservable(this);
  }

  load_eDocs = async (partId: string) => {
    await eDocLoader(partId).then((res) => {
      this.eDocuments = res;
    });
  };

  setRightGridHeight = (height: number) => {
    this.rightGridHeight = height;
  };

  setAlarm = (alarm: any) => {
    this.alarm = alarm;
  };
}
