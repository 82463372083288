import React from "react";
import { useCallback } from "react";
import Part3d from "../../CasterSetup/components/Part3d";
import { installationTreeResource } from "../../../services/suspenders";

interface Props {
  partId: string | null;
  setPartId: (partId: string | null) => void;
}

const Part3dView = ({ partId, setPartId }: Props) => {
  const { installationPositions } = installationTreeResource.read();

  let part = undefined;
  if (partId) {
    part = installationTreeResource.readPart(partId);
  }

  const handlePositionClick = useCallback(
    (positionId) => {
      const position = installationPositions[positionId];
      setPartId(position?.currentlyInstalled?.id || null);
    },
    [installationPositions, setPartId]
  );

  return (
    <>
      <Part3d partId={partId} onPositionClick={handlePositionClick} />
      <div>
        {part && (
          <>
            <div>{part?.name}</div>
            <div>{part?.remarks}</div>
          </>
        )}
      </div>
    </>
  );
};

export default Part3dView;
