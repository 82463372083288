import React, { useState, useContext, Suspense } from "react";

// Material UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

//internals
import SelectPanels from "./components/select-panels";
import { PresentationContext } from "../../context/presentation-context";
import Part3dView from "./components/partsRemarks";

import { OmitIfUnAuthorized } from "../../components/RequireAuthorization";
import { enRoles } from "../../app/models/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    minHeight: 500,
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "84vh",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  gridItems: {
    paddingTop: "30px",
    justifyContent: "space-between",
    paddingLeft: "30px",
  },
  legend: {
    fontSize: 9,
    width: "100%",
    textAlign: "center",
  },
  image: {
    width: 300,
    cursor: "pointer",
  },
}));

export default function AddCockPit() {
  const [showItems, setShowItems] = useState(false);
  const [isValid, setIsvalid] = useState(false);

  const [partId, setPartId] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(PresentationContext);

  const [selectedItems, setselectedItems] = useState([]);

  const classes = useStyles();
  const [cockPitName, setCockPitName] = useState("");

  const history = useHistory();

  const goto = (cockPit) => {
    dispatch({
      type: "SET_CURRENT_PRESENTATION",
      payload: cockPit,
    });

    history.push("/app/cockpits/prepare");
  };

  const prepareCockpit = () => {
    // save into store to get on prepareCockpits component
    let cockPit = {
      name: cockPitName,
      status: "ACTIVATED",
      partId: partId,
      ContentType: "C",
      pannels: [],
    };
    selectedItems.map((node) =>
      cockPit.pannels.push({
        id: node.id,
        ContentType: "C",
        name: node.name,
        visualization_type: node.visualization_type,
      })
    );

    goto(cockPit);
  };

  const handleTextChange = (event) => {
    setCockPitName(event.target.value ?? "");
    setIsvalid(event.target.value.length > 0 && selectedItems.length > 0);
    setShowItems(partId !== "" && event.target.value !== "");
    if (!event.target.value) setselectedItems([]);
  };

  const handlerItemChecked = (cockpitItem, checked) => {
    const index = selectedItems.indexOf(cockpitItem);
    // prevent reinsert item
    if (checked && index === -1) {
      selectedItems.push(cockpitItem);
    } else {
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
    }
    setselectedItems(selectedItems);
    setIsvalid(cockPitName.toString() !== "" && selectedItems.length > 0);
  };

  return (
    <>
      <Container maxWidth="lg" className={classes.header}>
        New Cockpit for User: Sys User
      </Container>
      <Container maxWidth="lg" className={classes.header}>
        Name of Cockpit:
        <input
          type="text"
          value={cockPitName}
          placeholder={"Please type the name"}
          onChange={handleTextChange}
        />
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={8} md={8} lg={6}>
            <div
              style={{
                display: "block",
                position: "absolute",
                width: "600px",
                height: "500px",
              }}
            >
              <OmitIfUnAuthorized
                roles={[
                  enRoles.CASTER_READ,
                  enRoles.CASTER_ADMIN,
                  enRoles.COCKPITS_ADMIN,
                ]}
              >
                <Suspense fallback="Loading  <Part3dView /> from <AddCockPit /> ...">
                  <Part3dView partId={partId} setPartId={setPartId} />
                </Suspense>
              </OmitIfUnAuthorized>
            </div>
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <SelectPanels
              handlerItemChecked={handlerItemChecked}
              showItems={showItems}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" m={1} p={1}>
              <Box p={1}>
                {isValid && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => prepareCockpit()}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
