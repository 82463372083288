import React, { useEffect, useState } from "react";

import PrepareDashboard from "./prepare-dashboard";

export default function AddDashboard() {
  const [dashboard] = useState({
    name: "",
    ContentType: "D",
    Status: "ACTIVATED",
    pannels: [],
    pannelsContent: [],
  });
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return <>{isLoaded && <PrepareDashboard current={dashboard} />}</>;
}
