import React from "react";
import { Doughnut } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import { ChartProps } from "./chartProps";

const useStyles = makeStyles(() => ({
  container: {
    height: "80%",
  },
}));

const DoughnutChart = (chartProps: ChartProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Doughnut
        data={chartProps.data}
        options={{
          ...chartProps.options,
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
};

export default DoughnutChart;
