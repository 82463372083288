import React, { useEffect, useState } from "react";

//Material-UI
import {TextField, Autocomplete} from "@mui/material";
import { Agent } from "../../../app/api/agent";
import { PartItem } from "../../../app/models/partitem";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  autocompleteTextField: theme.autocompleteTextField
}));

interface Props {
  onItemSelect: (value: PartItem) => void;
  width: string;
}

export default function PartSearch({ onItemSelect, width }: Props) {
  const [nodeList, setNodeList] = useState<PartItem[]>([]);
  const [value] = React.useState(nodeList[0]);
  const [filterPart, setFilterPart] = useState("");
  const classes = useStyles();
  const { t } = useTranslation();
  const handleChange = (_event: React.ChangeEvent<{}>, value: string) => {
    setFilterPart(value);
  };

  useEffect(() => {
    (async () => {
      if (filterPart.length > 2) {
        const parts = await Agent.Parts.list(filterPart);
        if (parts) {
          setNodeList(parts);
        }
      }
    })();
  }, [filterPart]);

  const handleSelect = (
    _event: React.ChangeEvent<{}>,
    value?: PartItem | any
  ) => {
    if (value && value?.id) {
      onItemSelect(value);
    }
  };

  return (
    <div style={{ width: width }}>
      <Autocomplete
      className={classes.autocompleteTextField}
        id="nodeListOptions"
        options={nodeList}
        popupIcon={<KeyboardArrowDownIcon/>}
        onInputChange={handleChange}
        onChange={handleSelect}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}

            variant="outlined"
            placeholder={t("page.plantiew.enterSearchText")}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...params.InputProps,

                startAdornment: (
    <SearchIcon />
              ),
              }}


          />
        )}
        value={value}
      />
    </div>
  );
}
