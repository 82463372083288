import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

import { CockPitTreeNode } from "../../../../app/models/cockPitTreeNode";
import { PannelLocation } from "../../../../app/models/presentation";

import TreeViewPannel from "./treeview";
import { RUNNER_ROW_HEIGHT, RUNNER_COL_WIDTH } from "../../../../constants";
import { MapInteractionCSS } from "react-map-interaction";
import SGV from "react-inlinesvg";
import PleaseSelectNode from "../../../../assets/icons/PleaseSelectNode.svg";

import Agent from "../../../../services/eDocService";

interface Props {
  panel?: PannelLocation;
  partId?: string;
}

function EDoc({ panel, partId }: Props) {
  const [nodes, setNodes] = useState<CockPitTreeNode[]>([]);
  const [node, setNode] = useState<CockPitTreeNode>();
  const [image, setImage] = useState<string>(PleaseSelectNode);
  const [selectedPart] = useState(partId ?? "2110590_MMK");
  const [initialZoom, setInitialZoom] = useState(1);
  const [maxHeight, setMaxHeight] = useState(RUNNER_ROW_HEIGHT);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMaxHeight(
      panel ? panel.h * RUNNER_ROW_HEIGHT * 0.85 : RUNNER_ROW_HEIGHT * 0.85
    );
  }, [panel]);

  const getNodes = useCallback(async () => {
    const body = {
      catalogs: [selectedPart],
      catalogTypes: [0],
      locale: "en",
      search: {
        articlenumber: ["21031%"],
      },
      attrs: ["articlenumber", "partname", "media_vector", "quantity"],
    };

    const result = await Agent.EDocs.getCatalogAttributeValues(body);
    let childrenNodes: CockPitTreeNode[] = [];
    result.forEach((edoc, index) => {
      childrenNodes.push({
        text: edoc.partname,
        label: `${edoc.partname}`,
        media_vector: edoc.media_vector,
        id: index.toString(),
        name: edoc.partname,
        children: [],
      });
    });
    setNodes([
      {
        id: selectedPart,
        label: selectedPart,
        text: selectedPart,
        name: selectedPart,
        children: childrenNodes,
      },
    ]);
  }, [selectedPart]);

  useEffect(() => {
    // Some synchronous code.
    (async () => {
      await getNodes();
    })();
  }, [getNodes]);

  const loadImage = useCallback(async (node: CockPitTreeNode) => {
    setLoading(true);

    //todo think about parts
    if (node.media_vector) {
      const firstPage = node.media_vector?.split("#")[0];
      if (firstPage) {
        const result = await Agent.EDocs.getFile({ file: firstPage });
        setInitialZoom(1);
        setImage(
          result.replace(
            `onload="if(typeof(parent.fileLoaded) != 'undefined') parent.register2();"`,
            ``
          )
        );
      }
    }
    setLoading(false);
  }, []);

  const selectedItemOnTree = useCallback(
    async (node: CockPitTreeNode) => {
      setNode(node);
      await loadImage(node);
    },
    [loadImage]
  );

  return (
    <>
      <div style={{ width: "100%", textAlign: "center", height: 25 }}>
        {!loading && node && <>{node?.text}</>}
        {loading && <>Loading...</>}
      </div>
      <Grid container item sm={12}>
        <Grid item xs={4} sm={3}>
          <TreeViewPannel
            onSelectecItem={selectedItemOnTree}
            panel={panel}
            nodes={nodes}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <div
            id="divImage"
            style={{
              width: RUNNER_COL_WIDTH * 2,
              left: -100,
              height: maxHeight,
              borderTopColor: "lightgray",
              borderStyle: "dashed",
            }}
          >
            <MapInteractionCSS
              name="mapInteractionCss"
              defaultScale={initialZoom}
              scale={initialZoom}
              minScale={0.5}
              maxScale={15}
              showControls
            >
              {!loading && image && (
                <SGV
                  height={maxHeight}
                  width={RUNNER_COL_WIDTH * 2}
                  imageRendering={"Please Wait"}
                  cacheRequests={false}
                  style={{
                    height: maxHeight,
                    width: RUNNER_COL_WIDTH * 2,
                  }}
                  src={image}
                  title={selectedPart}
                />
              )}
            </MapInteractionCSS>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default EDoc;
