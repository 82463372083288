import React from "react";
import { installationTreeResource } from "../../../../../services/suspenders";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
//Material-UI
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({

  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  title: {
    color: theme.textSecondary,
    width: "100%",
  },
  link: {
    display: "inline",
    cursor: "pointer",
    fontSize: 17,
    color: theme.link,
    border: `1px solid ${theme.mediumContrastBorder}`,
    borderRadius: 10,
    marginRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 2,
    paddingLeft: 2,
  },
  valueDiv: {
    width: "100%",
    marginBottom: 10,
  },
  value: {
    fontSize: 17,
    display: "inline",
  },
  linkValueDiv: {
    width: "100%",
    marginTop: 5,
    marginBottom: 15,
  },
  grid:{
    padding:0,
    marginBottom:10
  }
}));

interface Props {
  partId: string;
  positionFromPart: any;
  setMainView: any;
}

export default function GeneralDetails({
  partId,
  positionFromPart,
  setMainView,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { installableParts } = installationTreeResource.read();
  const part = installableParts[partId];
  const currentPosition = part.currentlyInstalledAt;
  const positionName = currentPosition?.name;
  if (partId === "c90afb55-7edd-4003-87ab-00ec73b9d65a") {
    return (
    <div>
      <Grid container className={classes.grid} >
      <Typography className={classes.title} variant="caption">
        {t("grid.label.name")}
      </Typography>
      <div >
        <p className={classes.value}>{part.name}</p>
      </div>
      </Grid>

      <Grid container className={classes.grid} >
      <Typography className={classes.title} variant="caption">
        {t("grid.label.id")}
      </Typography>
      <div >
        <p className={classes.value}>{part.id}</p>
      </div>
      </Grid>

      <Grid
            container
            style={{ width: "100%" }}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid xs={2.5} className={classes.grid} item >
            <Typography className={classes.title} variant="caption">
        {t("castersetup.details.grade")}
      </Typography>
      <div>
        <p className={classes.value}>1100</p>
      </div>
            </Grid>
            <Grid xs={3} item className={classes.grid}>
            <Typography className={classes.title} variant="caption">
            {t("castersetup.details.heatID")}
      </Typography>
      <div >
        <p className={classes.value}>00000157</p>
      </div>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ width: "100%", marginTop: 10 }}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid xs={2.5} item className={classes.grid} >
              <Typography className={classes.title} variant="caption">
              {t("castersetup.details.startTime")}
              </Typography>
              <div >
                <p className={classes.value}>2021-06-01 08:30</p>
              </div>
            </Grid>
            <Grid xs={2} item className={classes.grid}>
              <Typography className={classes.title} variant="caption">
                {t("castersetup.details.castTons")}
              </Typography>
              <div >
                <p className={classes.value}>200 t</p>
              </div>
            </Grid>
          </Grid>
      </div>)
  }
  else {
    return (
      <div>
        <Typography className={classes.title} variant="caption">
          {t("grid.label.name")}
        </Typography>
        <div className={classes.valueDiv}>
          <p className={classes.value}>{part.name}</p>
        </div>

        <Typography className={classes.title} variant="caption">
          {t("grid.label.id")}
        </Typography>
        <div className={classes.valueDiv}>
          <p className={classes.value}>{part.id}</p>
        </div>

        <Typography className={classes.title} variant="caption">
          {t("castersetup.details.consumes")}
        </Typography>
        <div className={classes.valueDiv}>
          <p className={classes.value}>Energy</p>
        </div>

        <Typography className={classes.title} variant="caption">
          {t("castersetup.details.isPartOf")}
        </Typography>
        <div className={classes.valueDiv}>
          <p className={classes.value}>drive chain</p>
        </div>

        <Typography className={classes.title} variant="caption">
          {t("castersetup.details.isInstalledAt")}
        </Typography>
        {currentPosition ? (
          <div className={classes.linkValueDiv}>
            <p
              className={classes.link}
              onClick={() => {
                if (currentPosition) {
                  history.push(`../caster/${currentPosition.belongsTo.id}`);
                  positionFromPart(
                    currentPosition.id,
                    currentPosition.belongsTo.id
                  );
                  setMainView("3");
                }
              }}
            >
              {`${positionName}`}
            </p>
          </div>
        ) : (
          <div className={classes.valueDiv}>
            <p className={classes.value}>
              {t("castersetup.details.notInstalled")}
            </p>
          </div>
        )}
        {currentPosition ? (
          <Grid
            container
            style={{ width: "100%", marginBottom: 10 }}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid xs={2} item>
              <Typography className={classes.title} variant="caption">
                {t("castersetup.details.installationDate")}
              </Typography>
              <div>
                <p className={classes.value}>2021-06-01</p>
              </div>
            </Grid>
            <Grid xs={2} item>
              <Typography className={classes.title} variant="caption">
                {t("castersetup.details.castTons")}
              </Typography>
              <div>
                <p className={classes.value}>200 t</p>
              </div>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Typography className={classes.title} variant="caption">
          {t("castersetup.details.canBeInstalledAt")}
        </Typography>
        {part.installableAt.length !== 0 ? (
          <Grid container style={{ marginTop: -15 }}>
            {part.installableAt.map((position) => (
              <p
                key={position.id}
                className={classes.link}
                onClick={() => {
                  if (currentPosition) {
                    history.push(`../caster/${position.belongsTo.id}`);
                    positionFromPart(position.id, position.belongsTo.id);
                    setMainView("3");
                  }
                }}
              >
                {position.name}
              </p>
            ))}
          </Grid>
        ) : (
          <div>
            <p className={classes.value}>
              {t("castersetup.details.noPlaceAvailable")}
            </p>
          </div>
        )}
      </div>
    );
  }
}
