import { enPermission } from "../../app/models/permissions";
import { environment } from "../../environments/environments";
import { isAuthenticated } from "../../services/auth";

import PermissionsService from "../../services/permissionsService";

const basePath = process.env.PUBLIC_URL;

const SignIn = () => {
  // if auth previously redirect to app,
  if (isAuthenticated()) {
    // redirect to path that user has access:
    if (PermissionsService.hasPermission(enPermission.ASSET_MANAGEMENT_READ)) {
      window.location.href = `${basePath}/app`;
      return;
    }
    if (PermissionsService.hasPermission(enPermission.COCKPITS_READ)) {
      window.location.href = `${basePath}/app/cockpits`;
      return;
    }
    if (PermissionsService.hasPermission(enPermission.DASHBOARDS_READ)) {
      window.location.href = `${basePath}/app/dashboards`;
      return;
    }
    if (PermissionsService.hasPermission(enPermission.CASTER_READ)) {
      window.location.href = `${basePath}/app/caster`;
      return;
    }
    return (window.location.href = `${basePath}/app/unauthorized`);
  }

  const redirectUrl = `${environment.CUSTOMER_PORTAL}?redirect_url=/authorize?redirect_url=${environment.CALLBACK_URL}`;

  window.location = redirectUrl;
};

export default SignIn;
