import React, { useState } from "react";
import {
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@material-ui/core/Typography";
import { Agent } from "../../../../app/api/agent";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import EquipmentBody from "./Body";
import { Part } from "../../../../app/models/part";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: { ...theme.darkButton, marginLeft: 2 },
  button2: { ...theme.darkButton, color: theme.link, backgroundColor: "transparent" }


}));


interface Props {
  iconLaunch: boolean;
}

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AddEquipment({ iconLaunch }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState(-1);
  const [remarks, setRemarks] = useState("");
  const classes = useStyles()
  const handleSave = async () => {
    //TODO: Validate
    if (type !== -1 && name) {
      const payload = {
        name: name,
        remarks: remarks,
        nodeTypeId: type,
      };
      await Agent.Parts.create(payload as Part).then(() => {
        setOpenAlert(true);
        //TODO: Refresh equipment
      });
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  function launchButton() {
    if (iconLaunch) {
      return (
        <IconButton aria-label="addprop" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      );
    } else {
      return (
        <Button className={classes.button} endIcon={<AddIcon />} variant="contained" onClick={handleClickOpen}>
          {t("page.addequipment.button")}
        </Button>
      );
    }
  }

  return (
    <div>
      {launchButton()}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t("page.addequipment.label")}
        </DialogTitle>
        <DialogContent dividers>
          <EquipmentBody
            onNameChange={(e) => setName(e)}
            onSelectType={(e) => setType(e)}
            onRemarksChange={(e) => setRemarks(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus className={classes.button2} onClick={handleSave} color="primary">
            {t("button.save")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="success" onClose={handleCloseAlert}>
          {t("page.plantview.alert")}
        </Alert>
      </Snackbar>
    </div>
  );
}
