import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Modal,
  Typography,
} from "@mui/material";
import { MapInteractionCSS } from "react-map-interaction";
import SVG from "react-inlinesvg";
import { CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/store";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardImage: {
    alignSelf: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "white",
  },
  card: {
    maxWidth: "60%",
    marginBottom: 20,
    "&.MuiCard-root": {
      backgroundColor: theme.pageBackground,
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    backgroundColor: theme.textPrimary,
  },
  modalImage: {
    alignSelf: "center",
    height: "100%",
    width: "100%",
    backgroundColor: theme.pageBackgroundSecondary,
  },
}));

interface Props {
  docs?: any;
  partId: string;
}

export default observer(function EDoc({ partId }: Props) {
  const classes = useStyles();
  const { casterSetupStore } = useStore();
  const { eDocuments } = casterSetupStore;
  const [clickimage, setclickimage] = useState<any>();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  let cardKeys = 0;
  return (
    <>
      {eDocuments
        ? eDocuments.map((x: any) => (
            <Card key={cardKeys++} className={classes.card}>
              <CardActionArea>
                <CardContent>
                  <Grid
                    container
                    onClick={() => {
                      setOpen(true);
                      setclickimage(x);
                    }}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={6}>
                      <SVG
                        className={classes.cardImage}
                        key={x.partname}
                        imageRendering={"Please Wait"}
                        cacheRequests={false}
                        src={x.image}
                        title={x.partname}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >
                        {x.partname}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        : undefined}

      <Modal
        open={open}
        onBackdropClick={() => {
          handleClose();
        }}
      >
        <div className={classes.modal} id="divImage">
          <MapInteractionCSS
            name="mapInteractionCss"
            defaultScale={0.5}
            scale={0.5}
            minScale={0.5}
            maxScale={10}
            showControls
          >
            <SVG
              className={classes.modalImage}
              loader={<CircularProgress />}
              imageRendering={"Please Wait"}
              cacheRequests={false}
              src={clickimage ? clickimage.image : undefined}
              title={clickimage ? clickimage.partname : undefined}
            />
          </MapInteractionCSS>
        </div>
      </Modal>
    </>
  );
});
