/* eslint-disable react/prop-types */
import React from "react";
import Gauge from "./gauge";
import CardChart from "./chart";
import GeniusCMTreeview from "./geniusCmTreeView";
import JobList from "./list";
import TrafficLigth from "./traffic";
import EDoc from "./eDoc";
import { Pannel } from "../../../../app/models/presentation";
import { CockPitTreeNode } from "../../../../app/models/cockPitTreeNode";
import { DECJobs } from "../../../../app/models/decJobs";

import { ChartData } from "chart.js";

interface Props {
  cockPitItem: Pannel;
  jobs: DECJobs[];
  selectedNode: (node: CockPitTreeNode) => void;
  alarm?: number;
  chartData?: {
    data: ChartData;
  };
  nodeName: string;
  partId?: string;
}

export default function CockPitPannel({
  cockPitItem,
  jobs,
  selectedNode,
  alarm,
  chartData,
  nodeName,
  partId,
}: Props) {
  const selectedItemOnTree = (node: CockPitTreeNode) => {
    selectedNode(node);
  };

  return (
    <>
      {cockPitItem.visualization_type === "tree" && (
        <GeniusCMTreeview
          onSelectecItem={selectedItemOnTree}
          panel={cockPitItem.pannelLocation}
        />
      )}
      {cockPitItem.visualization_type === "chart" && (
        <CardChart
          alarmCode={alarm}
          chartData={chartData}
          nodeName={nodeName}
          panel={cockPitItem.pannelLocation}
        />
      )}
      {cockPitItem.visualization_type === "signal" && (
        <TrafficLigth active={alarm} />
      )}
      {cockPitItem.visualization_type === "gauge" && <Gauge alarm={alarm} />}
      {cockPitItem.visualization_type === "list" && <JobList jobs={jobs} />}
      {cockPitItem.visualization_type === "e-doc" && (
        <EDoc panel={cockPitItem.pannelLocation} partId={partId} />
      )}
    </>
  );
}
