import axios, { AxiosResponse } from "axios";
import { Plantview, PlantviewType } from "../app/models/plantview";
import { environment } from "../environments/environments";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const baseURL = environment.ASSET_DICTIONARY_API;

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Plantviews = {
  list: () => request.get<PlantviewType[]>(`${baseURL}/Plantviews`),
  filtered: (startingPart: string, viewTypeId: number) =>
    request.get<Plantview>(
      `${baseURL}/Plantviews/${startingPart}?viewTypeId=${viewTypeId}`
    ),
  children: (id: string, viewTypeId: number) =>
    request.get<Plantview>(
      `${baseURL}/Plantviews/children?id=${id}&viewTypeId=${viewTypeId}`
    ),
  all: (startingPart: string) =>
    request.get<Plantview>(`${baseURL}/Plantviews/${startingPart}`),
};

export const Agent = {
  Plantviews,
};
