import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { getToken } from "./services/auth";
import APIMessageProvider from "./common/providers/APIMessageProvider";
import APIErrorNotification from "./components/APIMessageNotification";
import APIProgressBarProvider from "./common/providers/APIProgressBar";
import { createBrowserHistory } from "history";
import i18n from "./i18n/config";
import { I18nextProvider } from "react-i18next";
import { environment } from "./environments/environments";

export const history = createBrowserHistory();

axios.defaults.baseURL = environment.API_URL;

axios.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <APIMessageProvider>
      <APIProgressBarProvider>
        <App />
        <APIErrorNotification />
      </APIProgressBarProvider>
    </APIMessageProvider>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
