import React, { useState } from "react";

//Material UI
import { Box, makeStyles, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    alignItems: "center",
  },
  label: {
    flexBasis: "40%",
    maxWidth: "40%",
  },
  item: {
    flexBasis: "60%",
    maxWidth: "60%",
  },
}));

interface Props {
  onNameChange: (value: string) => void;
  onValueChange: (value: string) => void;
}

export default function Attribute({ onNameChange, onValueChange }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [name, setName] = useState("");

  const handleNameChange = (value: string) => {
    setName(value);
    onNameChange(value);
  };

  const handleValueChange = (value: string) => {
    setValue(value);
    onValueChange(value);
  };

  return (
    <>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.addproperty.value.label")}
        </Box>
        <TextField
          id="propertyValue"
          className={classes.item}
          onChange={(e) => {
            handleValueChange(e.target.value as string);
          }}
          value={value}
        />
      </Box>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("page.addproperty.name.label")}
        </Box>
        <TextField
          id="propertyName"
          className={classes.item}
          onChange={(e) => {
            handleNameChange(e.target.value as string);
          }}
          value={name}
        />
      </Box>
    </>
  );
}
