/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// this is used to show state of some alarm.
const useStyles = makeStyles({
  light: {
    margin: "auto",
    width: "25px",
    height: "25px",
    borderRadius: "100%",
  },
  red: {
    marginTop: "10px",
    backgroundColor: "red",
  },
  green: {
    marginTop: "10px",
    backgroundColor: "green",
  },
  yellow: {
    marginTop: "10px",
    backgroundColor: "yellow",
  },
  grey: {
    marginTop: "10px",
    backgroundColor: "grey",
  },
  lighted: {
    boxShadow: "#FFFFFF",
  },
});

interface Props {
  active?: number;
}

export default function TrafficLigth({ active }: Props) {
  const classes = useStyles();

  const lightRed = clsx(classes.light, classes.red);
  const lightGreen = clsx(classes.light, classes.green);
  const lightYellow = clsx(classes.light, classes.yellow);
  const lightGrey = clsx(classes.light, classes.grey);
  const [activeStyle, setActiveStyle] = useState(lightGrey);

  const updateLights = useCallback(() => {
    if (!active) setActiveStyle(lightGrey);
    switch (active) {
      case 1:
        setActiveStyle(lightYellow);
        break;
      case 2:
        setActiveStyle(lightRed);
        break;
      case 0:
        setActiveStyle(lightGreen);
        break;
      default:
        setActiveStyle(lightGrey);
        break;
    }
  }, [active, lightGreen, lightGrey, lightRed, lightYellow]);

  useEffect(() => {
    updateLights();
  }, [active, updateLights]);

  return (
    <div>
      <div id="bar"></div>
      <div id="semaphore">
        <div className={activeStyle}></div>
      </div>
    </div>
  );
}
