import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AssetTree from "./AssetTree";
import EquipmentList from "./components/EquipmentView/EquipmentList";
import { useTranslation } from "react-i18next";
import RequireAuthorization, {
  OmitIfUnAuthorized,
} from "../../components/RequireAuthorization/RequireAuthorization";
import { makeStyles } from "@material-ui/core/styles";
//permissions
import { enPermission, enRoles } from "../../app/models/permissions";
import { TabPanel, TabContext } from "@mui/lab";
import useWindowSize from "../../app/customHooks";

const useStyles = makeStyles((theme) => ({

  tab: theme.tab,
  innerTab: theme.innerTab,

  fixedHeight: {
    backgroundColor: "transparent",
  },

  fixedHeight2: {
    backgroundColor: theme.pageBackgroundSecondary,
  },

}));



function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function AssetDictionary() {
  const windowHeight = useWindowSize()[0];
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <RequireAuthorization
      roles={[
        enRoles.ASSET_MANAGEMENT_ADMIN,
        enRoles.ASSET_MANAGEMENT_STANDARD,
      ]}
      permissions={enPermission.ASSET_MANAGEMENT_READ}
    >
      <div className={classes.fixedHeight} style={{ minHeight: windowHeight - 64 - 10 }}>
        <TabContext value={`${value}`} >

          <Tabs
            TabIndicatorProps={{ style: { top: "0px" } }}
            style={{ marginLeft: 24 }}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
          >
            <Tab label={t("tab.plantview")} {...a11yProps(0)}
              className={classes.tab} />
            <Tab label={t("tab.equipmentlist")} {...a11yProps(1)}
              className={classes.tab} />
          </Tabs>
          <div className={classes.fixedHeight2} style={{ minHeight: windowHeight - 64 - 10 - 48, marginLeft: 24, marginRight: 24, paddingTop: 25, paddingLeft: 15, paddingRight: 15 }}>
            <TabPanel style={{ padding: 0 }} value={"0"} dir={theme.direction} >
              <AssetTree />
            </TabPanel>
            <TabPanel value={"1"} dir={theme.direction}>
              <EquipmentList />
            </TabPanel>
            <OmitIfUnAuthorized roles={[enRoles.CASTER_ADMIN]}>
            </OmitIfUnAuthorized>
          </div>
        </TabContext>
      </div>
    </RequireAuthorization>
  );
}
