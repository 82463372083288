import React, { Fragment, useContext } from "react";

//Material-UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";

import AssetContext from "../../../context/asset-context";
import { Agent } from "../../../app/api/agent";
import { enPermission } from "../../../app/models/permissions";
import PermissionsService from "../../../services/permissionsService";
import DeleteIcon from "@mui/icons-material/Delete";
import useWindowSize from "../../../app/customHooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  showLink: {
    display: "flex",
    justifyContent: "flex-end",
  },
  header: {
    justifyContent: "left",
    display: "flex",
    width: "100%",
    fontWeight: "bold",
    backgroundColor: theme.pageBackgroundTertiary,
  },
  tableDiv: {
    backgroundColor: theme.lightBackground,

    overflowY: "auto",
  },
  box: {
    borderTop: `1px solid ${theme.border}`,
  },
  actionButton: theme.actionButton,
}));

export default function ListProporties() {
  const windowHeight = useWindowSize()[0];
  const classes = useStyles();
  const assetContext = useContext(AssetContext);
  const { partDetails, refreshPartDetails, refreshTree, selectedPart } =
    assetContext;
  const { properties, systems } = partDetails;
  const { t } = useTranslation();

  const handleDeleteProperty = async (id) => {
    if (id) {
      await Agent.Parts.deleteProperty(id);
      refreshPartDetails();
      refreshTree();
    }
  };

  return (
    <>
      <Box display="flex" p={0}>
        <Box p={1} className={classes.header}>
          {selectedPart && selectedPart.name}
        </Box>
      </Box>
      <div
        className={classes.tableDiv}
        style={{ maxHeight: windowHeight - 64 - 10 - 50 - 45 - 50 - 25 }}
      >
        {properties.map((item, i) => {
          return (
            <Box key={i} display="flex" p={0} className={classes.box}>
              <Box p={1} width="26%">
                {item.type}
              </Box>
              <Box p={1} width="26%">
                {item.relationship ?? item.name}
              </Box>
              <Box p={1} width="26%">
                {item.value}
              </Box>
              <Box p={1} width="22%" className={classes.showLink}>
                {PermissionsService.hasPermission(
                  enPermission.ASSET_MANAGEMENT_WRITE
                ) && (
                  <>
                    <Button
                      startIcon={<DeleteIcon />}
                      className={classes.actionButton}
                      onClick={() => handleDeleteProperty(item.id)}
                    >
                      {t("button.delete")}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
        {systems.map((item) => {
          return (
            <Box key={item.id} display="flex" p={0} className={classes.box}>
              <Box p={1} width="26%" style={{ wordWrap: "break-word" }}>
                {item.externalSystem}
              </Box>
              <Box p={1} width="26%" style={{ wordWrap: "break-word" }}>
                {item.systemIdentifier}
              </Box>
              <Box p={1} width="26%" style={{ wordWrap: "break-word" }}>
                {item.description}
              </Box>
              <Box p={1} width="22%" className={classes.showLink}>
                {PermissionsService.hasPermission(
                  enPermission.ASSET_MANAGEMENT_WRITE
                ) && (
                  <>
                    <Button
                      className={classes.actionButton}
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteProperty(item.id)}
                    >
                      {t("button.delete")}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </div>
    </>
  );
}
