import React, { useReducer, createContext } from "react";

export const PresentationContext = createContext();

const initialState = {
  presentations: [],
  currentPresentation: null,
  loading: false,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_PRESENTATION":
      return {
        presentations: [...state.presentations, action.payload],
      };
    case "SET_CURRENT_PRESENTATION":
      return {
        currentPresentation: action.payload,
      };
    case "GET_CURRENT_PRESENTATION":
      return state.currentPresentation;
    case "CLEAN_CURRENT_PRESENTATION":
      return { ...state, currentPresentation: null };
    case "START":
      return {
        loading: true,
      };
    case "COMPLETE":
      return {
        loading: false,
      };
    default:
      return {
        loading: false,
      };
  }
};

export const PresentationsContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PresentationContext.Provider value={[state, dispatch]}>
      {props.children}
    </PresentationContext.Provider>
  );
};
