/* eslint-disable no-unused-vars */
export interface Presentation {
  _id: string;
  name: string;
  ContentType: PresentationContentType;
  status: PresentationStatus;
  partId: string;
  partName: string;
  createdBy: string;
  pannels: Pannel[];
}

export enum PresentationContentType {
  Dashboard = "D",
  Cockpit = "C",
}

export enum PresentationStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export interface Pannel {
  partId?: any;
  content?: any;
  pannelId?: string;
  public?: string;
  title?: string;
  name: string;
  id: string;
  label?: string;
  text?: string;
  updateTime?: string;
  visualization_type: string;
  pannelContent?: any;
  pannelLocation?: PannelLocation;
}

export interface PannelLocation {
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
}
