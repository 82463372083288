/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { Pannel } from "../../../app/models/presentation";

const useStyles = makeStyles({
  container: {
    height: "100%",
    minHeight: "200px",
    minWidth: "200px",
  },
});

interface Props {
  selectable: boolean;
  cockpitItem: Pannel;
  handlerChecked: (cockpitItem: Pannel, checked: boolean) => {};
  defaultCheck: boolean;
}

const CardItem = ({
  selectable,
  cockpitItem,
  handlerChecked,
  defaultCheck,
}: Props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(defaultCheck ?? false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    handlerChecked(cockpitItem, event.target.checked);
  };

  return (
    <Card className={classes.container}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            {selectable && (
              <Checkbox checked={checked} onChange={handleChange} />
            )}
          </IconButton>
        }
        title={cockpitItem.name}
      />

      <CardContent>
        <Typography paragraph>Type:</Typography>
        <Typography paragraph>{cockpitItem.visualization_type}</Typography>
      </CardContent>
    </Card>
  );
};

export default CardItem;
