import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const UnAuthorized: React.FC = () => {
  return (
    <>
      <Container maxWidth="lg">
        <Card>
          <CardContent>
            <h2>Sorry...</h2>

            <h5>you are not authorized to see this content</h5>
            <h6>Please contact the Platform Administrator</h6>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default UnAuthorized;
